import ErrorPage from "pages/ErrorPage";
import Home from "pages/Home";
import Games from "pages/Games";
import Contact from "pages/Contact";
import Ratings from "pages/Ratings";
import RatingsSystem from "pages/RatingsSystem";
import RatingsProcess from "pages/RatingsProcess";
import RatingsDownloads from "pages/RatingsDownloads";
import Parents from "pages/Parents";
import ParentsControls from "pages/ParentsControls";
import ParentsSafety from "pages/ParentsSafety";
import Hub from "pages/Hub";
import Schools from "pages/Schools";
import SchoolsAccounts from "pages/SchoolsAccounts";
import Retail from "pages/Retail";
import About from "pages/About";
import AboutTeam from "pages/AboutTeam";
import AboutHistory from "pages/AboutHistory";
import AboutMedia from "pages/AboutMedia";
import AboutCollab from "pages/AboutCollaborations";

import heroError from "assets/error_hero.jpg";
import heroContact from "assets/contact_hero.jpg";
import heroRatings from "assets/ratings_hero.jpg";
import heroRatingsSystem from "assets/ratings_system_hero.jpg";
import heroRatingsProcess from "assets/ratings_process_hero.jpg";
import heroRatingsDownloads from "assets/ratings_downloads_hero.jpg";
import heroParents from "assets/parents_hero.jpg";
import heroParentsControls from "assets/parents_controls_hero.jpg";
import heroParentsSafety from "assets/parents_safety_hero.jpg";
import heroHub from "assets/hub_hero.jpg";
import heroSchools from "assets/schools_hero.jpg";
import heroRetail from "assets/retail_hero.jpg";
import heroAbout from "assets/about_hero.jpg";
import heroAboutTeam from "assets/about_team_hero.jpg";
import heroAboutHistory from "assets/about_history_hero.jpg";
import heroAboutMedia from "assets/about_media_hero.jpg";
import heroAboutCollab from "assets/about_collab_hero.jpg";

const pages = [
  {
    title: "404 page not found",
    url: "error-404",
    component: ErrorPage,
    menuHide: true,
    hero: heroError,
  },
  {
    title: "Something has gone wrong",
    url: "error-internal",
    component: ErrorPage,
    menuHide: true,
    hero: heroError,
  },
  {
    title: "Home",
    url: "",
    component: Home,
    menuHide: true,
  },
  {
    title: "Games",
    url: "games",
    component: Games,
    menuHide: true,
  },
  {
    title: "Get In Touch",
    url: "contact",
    component: Contact,
    menuHide: true,
    hero: heroContact,
  },
  {
    title: "PEGI Ratings",
    url: "ratings",
    component: Ratings,
    menuHide: false,
    hero: heroRatings,
  },
  {
    title: "Our Ratings System",
    url: "ratings-system",
    component: RatingsSystem,
    menuHide: true,
    hero: heroRatingsSystem,
  },
  {
    title: "Our Processes",
    url: "ratings-process",
    component: RatingsProcess,
    menuHide: true,
    hero: heroRatingsProcess,
  },
  {
    title: "Document Downloads",
    url: "ratings-downloads",
    component: RatingsDownloads,
    menuHide: true,
    hero: heroRatingsDownloads,
  },
  {
    title: "Tools for Parents",
    url: "parents",
    component: Parents,
    menuHide: false,
    hero: heroParents,
  },
  {
    title: "Parental Controls",
    url: "parents-controls",
    component: ParentsControls,
    menuHide: true,
    hero: heroParentsControls,
  },
  {
    title: "Online Safety",
    url: "parents-safety",
    component: ParentsSafety,
    menuHide: true,
    hero: heroParentsSafety,
  },
  {
    title: "News",
    url: "hub",
    component: Hub,
    menuHide: false,
    hero: heroHub,
  },
  {
    title: "Schools",
    url: "schools",
    component: Schools,
    menuHide: false,
    hero: heroSchools,
  },
  {
    title: "Schools Accounts",
    url: "schools-accounts.html",
    component: SchoolsAccounts,
    menuHide: true,
    hero: heroSchools,
  },
  {
    title: "Retail Members",
    url: "retail",
    component: Retail,
    menuHide: false,
    hero: heroRetail,
  },
  {
    title: "About Us",
    url: "about",
    component: About,
    menuHide: false,
    hero: heroAbout,
  },
  {
    title: "Meet The Team",
    url: "about-team",
    component: AboutTeam,
    menuHide: true,
    hero: heroAboutTeam,
  },
  {
    title: "Our History",
    url: "about-history",
    component: AboutHistory,
    menuHide: true,
    hero: heroAboutHistory,
  },
  {
    title: "Media Centre",
    url: "about-media",
    component: AboutMedia,
    menuHide: true,
    hero: heroAboutMedia,
  },
  {
    title: "Collaborations",
    url: "about-collaborations",
    component: AboutCollab,
    menuHide: true,
    hero: heroAboutCollab,
  },
];

export default pages;
