import React from 'react';

import Template from 'structure/Template';
import Content from 'panels/Content';
import { PanelType } from 'resources/constants';
import More from 'panels/More';

const ParentsSafety = () => {
  return (
    <Template type="parents-safety">
      <More block="parents-safety-panel-1" />
      <Content type={PanelType.largeTickDark}>
        <More block="parents-safety-panel-2" />
      </Content>
    </Template>
  );
};

export default ParentsSafety;
