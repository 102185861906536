import React from 'react';

import Template from 'structure/Template';
import Explore from 'ratings/Explore';
import More from 'panels/More';

const RatingsSystem = () => {
  return (
    <Template type="ratings-system">
      <More block="ratings-system-panel-1" />
      <Explore />
    </Template>
  );
};

export default RatingsSystem;
