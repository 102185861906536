import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PanelType, globalStyle } from 'resources/constants';
import { fetchRestrictions } from 'resources/api';
import { IRestriction, IPlatform } from 'resources/interface';

import Content from 'panels/Content';

const useStyles = makeStyles(() =>
  Object.assign({}, globalStyle, {
    grid: {
      marginBottom: '5rem',
    },
    gridItem: {
      position: 'relative' as 'relative',
      '& img': {
        width: '100%',
      },
      '&:hover': {
        '& div': {
          display: 'none',
        },
      },
    },
  })
);

interface IProps {
  handleSelect?(obj: IRestriction): void;
  restriction: IRestriction | undefined;
  platform?: Partial<IPlatform>;
}
const ControlsRestrict = ({
  handleSelect,
  restriction,
  platform,
}:IProps) => {
  const classes = useStyles();
  const { data:payload } = useQuery(['restrictions'],fetchRestrictions);
  const restrictionId = restriction ? restriction.id : '';

  return (
    <>
      {platform && (
        <Content type={PanelType.plainDark}>
          <Link component={RouterLink} to="/parents-controls">
            {'<< Back to parental controls'}
          </Link>
          <Typography variant="h4" align="center" color="primary">
            {platform.platform_name}
          </Typography>
          <Grid container spacing={2} className={classes.grid}>
            {payload?.map(
              ({ id, restriction_name, restriction_icon_small }) => (
                <Grid item xs={6} md={3} className={classes.gridItem} key={id}>
                  <Link
                    component={RouterLink}
                    to={`/parents-controls-article/${id}/${platform.id}/${restriction_name}/${platform.platform_name}`}
                  >
                    <img src={restriction_icon_small} alt={restriction_name} />
                    {restriction && restriction.id !== id && (
                      <div className={classes.overlay} />
                    )}
                  </Link>
                </Grid>
              )
            )}
          </Grid>
        </Content>
      )}
      {handleSelect && (
        <Grid container spacing={4} className={classes.grid}>
          {payload?.map((restriction) => (
            <Grid
              item
              xs={6}
              md={3}
              className={classes.gridItem}
              onClick={() => handleSelect(restriction)}
              key={restriction.id}
            >
              <img
                src={restriction.restriction_icon_full}
                alt={restriction.restriction_name}
              />
              {restrictionId !== restriction.id && (
                <div className={classes.overlay} />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ControlsRestrict;
