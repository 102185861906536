import React, { useState } from "react";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { fetchArticles } from "resources/api";
import { globalStyle } from "resources/constants";

import SocialMedia from "hub/SocialMedia";
import Video from "panels/Video";
import { useQuery } from "@tanstack/react-query";

const useStyles = makeStyles(({ breakpoints }) =>
  Object.assign({}, globalStyle, {
    gridItem: {
      position: "relative" as "relative",
      textAlign: "center" as "center",
    },
    imgVid: {
      marginBottom: "2rem",
    },
    button: {
      [breakpoints.up("md")]: {
        marginTop: "initial",
        width: "14rem !important",
        position: "absolute" as "absolute",
        bottom: "2rem",
        right: "10rem",
      },
    },
  })
);

const LatestHub = () => {
  const classes = useStyles();
  const { data: payload } = useQuery(["articles"], fetchArticles);
  const [redirect, setRedirect] = useState(false);

  if (!payload) return null;
  const article = payload[0];
  const {
    id,
    article_title,
    article_image,
    article_video_url,
    release_date,
    author_id,
    author_name,
    author_job_title,
    author_external,
    teaser_copy,
  } = article;

  const author = author_id
    ? `${author_name}, ${author_job_title}`
    : author_external;

  if (redirect) {
    return <Navigate to={`/hub-article/${id}/${article_title}`} />;
  }

  return (
    <Grid item xs={12} md={6} className={classes.gridItem}>
      <Typography variant="h2" color="textSecondary">
        Latest news
      </Typography>
      <Box
        textAlign="left"
        className={classes.clickable}
        onClick={() => setRedirect(true)}
      >
        <Box className={classes.imgVid}>
          {article_image && (
            <img src={article_image} alt={article_title} width="100%" />
          )}
          {article_video_url && !article_image && (
            <Video url={article_video_url} />
          )}
        </Box>
        <SocialMedia article={article} reduced />
        <Typography variant="h5" gutterBottom>
          {article_title}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
        >{`${release_date} by ${author}`}</Typography>
        <Typography variant="body1" gutterBottom paragraph>
          {teaser_copy}
        </Typography>
      </Box>
      <Button
        component={RouterLink}
        to="/hub"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        More news
      </Button>
    </Grid>
  );
};

export default LatestHub;
