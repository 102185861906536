import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorBoundary, withSentryReactRouterV6Routing } from "@sentry/react";

import pages from "resources/pages";

//import { ContextProvider } from 'structure/Context';
import Tracking from "structure/Tracking";
//import ErrorBoundary from 'structure/ErrorBoundary';

import Games from "pages/Games";
import HubArticle from "pages/HubArticle";
import ParentsControlsArticle from "pages/ParentsControlsArticle";
import ErrorPage from "pages/ErrorPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const RenderError = (props: any) => <ErrorPage {...props} type="404" />;

const queryClient = new QueryClient();

const App = () => {
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);
  return (
    <ErrorBoundary fallback={<p>An error has occurred</p>}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <SentryRoutes>
            {pages.map(({ url, component: Component }) => (
              <Route key={url} path={`/${url}`} element={<Component />} />
            ))}
            <Route path="/hub-article/:id/:name" element={<HubArticle />} />
            <Route
              path="/parents-controls-article/:restrictionTypeId/:platformNameId/:restrictionType/:platformName"
              element={<ParentsControlsArticle />}
            />
            <Route path="/game/:id/:name" element={<Games />} />
            <Route path="/games/" element={<Games />} />
            <Route element={<RenderError />} />
          </SentryRoutes>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
