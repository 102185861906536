import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { LocalOffer as TagIcon } from '@material-ui/icons';

import { IArticle } from 'resources/interface';
import { globalStyle } from 'resources/constants';
import { Box, Typography } from '@material-ui/core';
import Video from 'panels/Video';

const useStyles = makeStyles(({ palette }) =>
  Object.assign({}, globalStyle, {
    box: {
      position: 'relative' as 'relative',
      backgroundColor: palette.common.white,
      padding: '2.6rem',
      height: '100%',
    },
    image: {
      width: '100%',
      height: 'auto',
    },
    bottom: {
      position: 'absolute' as 'absolute',
      bottom: '1.5rem',
    },
    tag: {
      color: palette.primary.main,
      transform: 'scale(-1,1)',
      width: '1.5rem',
      verticalAlign: 'middle',
      marginRight: '0.5rem',
    },
    title: {
      marginBottom: '20px',
    },
  })
);

interface IProps {
  article: IArticle;
}
const Thumb = ({ article }:IProps) => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const {
    id,
    article_title,
    article_image,
    article_video_url,
    release_date,
    tags,
  } = article;

  const image = article_image.includes(process.env.REACT_APP_HOST || 'https')
    ? article_image
    : `${process.env.REACT_APP_HOST}${article_image}`;

  if (redirect) {
    return <Navigate to={`/hub-article/${id}/${article_title}`} />;
  }

  return (
    <Box
      className={`${classes.box} ${classes.clickable}`}
      onClick={() => setRedirect(true)}
    >
      {image && !image.includes('workle-no-image') && (
        <img src={image} alt={article_title} className={classes.image} />
      )}
      {article_video_url && (!image || image.includes('workle-no-image')) && (
        <Video url={article_video_url} />
      )}
      <Typography variant="caption" color="textPrimary">
        {release_date}
      </Typography>
      <Typography variant="h2" color="primary" className={classes.title}>
        {article_title}
      </Typography>
      <Box className={classes.bottom}>
        <Typography variant="body1" color="textPrimary">
          <i>
            <TagIcon className={classes.tag} />
            {tags}
          </i>
        </Typography>
      </Box>
    </Box>
  );
};

export default Thumb;
