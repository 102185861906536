import React from 'react';

import Template from 'structure/Template';

import MoreImage from 'panels/MoreImage';
import More from 'ratings/More';
import FAQ from 'ratings/FAQ';

const Ratings = () => {
  return (
    <Template type="ratings">
      <More />
      <MoreImage block="ratings-panel-3" url="ratings-downloads" />
      <FAQ />
    </Template>
  );
};

export default Ratings;
