import { IContent } from './interface';

export const debugPlatform = (label: string, object: object | string) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`DEBUG: ${label}: ${JSON.stringify(object)}`);
  }
};

/*
export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find(key => object[key] === value);
}
*/

export const getBlock = (array: Array<IContent> | undefined, name: string) => {
  if (array && array.length) {
    const block = array.find((obj) => obj.block_name === name);

    if (block)
      return {
        title: block.block_title,
        content: block.block_content,
        image: block.block_image,
        video: block.block_video_url,
      };

    return {
      title: `[Dynamic Block "${name}" not found]`,
      content: `[Dynamic Block "${name}" not found]`,
    };
  }

  return {
    title: '',
    content: '',
  };
};
