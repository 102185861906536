import React from "react";
import { Formik, Form, FormikProps } from "formik";

import {
  makeStyles,
  Box,
  TextField,
  Button,
  Grid,
  FormControl,
} from "@material-ui/core";

import { ISchoolRegistration, RegistrationResponse } from "resources/interface";

import { useMutation } from "@tanstack/react-query";
import { postSchoolRegistration } from "resources/api";
import LoginForm from "./LoginForm";

const useStyles = makeStyles(({ palette, spacing }) => ({
  login: {
    display: "flex",
    flexWrap: "wrap",
    width: "fit-content",
    margin: "0 auto",
    paddingTop: "10px",
  },
  control: {
    width: "25rem",
  },
  button: {
    margin: `${spacing(2)}px 0 ${spacing(1)}px 0`,
  },
  error: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "white",
    color: "red",
    padding: "10px",
  },
  success: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "white",
    color: "green",
    padding: "10px",
    marginBottom: "30px",
  },
}));

const InnerForm = ({
  handleSubmit,
}: {
  handleSubmit: (formValues: ISchoolRegistration) => Promise<void>;
}) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        school_name: "",
        addr_line_1: "",
        addr_line_2: "",
        addr_town: "",
        addr_county: "",
        addr_postcode: "",
        addr_contact_phone: "",
        contact_title: "",
        contact_first_name: "",
        contact_surname: "",
        contact_email: "",
        contact_pwd: "",
      }}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<ISchoolRegistration>) => (
        <Form style={{ width: "100%" }}>
          <Box className={classes.login}>
            <Grid container spacing={8}>
              <Grid item md={6}>
                <FormControl className={classes.control}>
                  <TextField
                    label="Enter the name of your school"
                    name="school_name"
                    variant="filled"
                    margin="normal"
                    value={props.values.school_name}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Address line 1"
                    name="addr_line_1"
                    variant="filled"
                    margin="normal"
                    value={props.values.addr_line_1}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Address line 2"
                    name="addr_line_2"
                    variant="filled"
                    margin="normal"
                    value={props.values.addr_line_2}
                    onChange={props.handleChange}
                  />
                  <TextField
                    label="Town"
                    name="addr_town"
                    variant="filled"
                    margin="normal"
                    value={props.values.addr_town}
                    onChange={props.handleChange}
                  />
                  <TextField
                    label="County"
                    name="addr_county"
                    variant="filled"
                    margin="normal"
                    value={props.values.addr_county}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Postcode"
                    name="addr_postcode"
                    variant="filled"
                    margin="normal"
                    value={props.values.addr_postcode}
                    onChange={props.handleChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl className={classes.control}>
                  <TextField
                    label="School phone number"
                    name="addr_contact_phone"
                    type="tel"
                    variant="filled"
                    margin="normal"
                    value={props.values.addr_contact_phone}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Primary contact title (Mr, Mrs etc)"
                    name="contact_title"
                    variant="filled"
                    margin="normal"
                    value={props.values.contact_title}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Primary contact first name"
                    name="contact_first_name"
                    variant="filled"
                    margin="normal"
                    value={props.values.contact_first_name}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Primary contact surname"
                    name="contact_surname"
                    variant="filled"
                    margin="normal"
                    value={props.values.contact_surname}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Email address for login"
                    name="contact_email"
                    type="email"
                    variant="filled"
                    margin="normal"
                    value={props.values.contact_email}
                    onChange={props.handleChange}
                    required
                  />
                  <TextField
                    label="Password for login"
                    name="contact_pwd"
                    type="password"
                    variant="filled"
                    margin="normal"
                    value={props.values.contact_pwd}
                    onChange={props.handleChange}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.login}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
            >
              Register as a school
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const RegistrationForm = () => {
  const classes = useStyles();
  const {
    mutate,
    isError,
    isLoading,
    isSuccess,
    data: response,
  } = useMutation(postSchoolRegistration);

  const handleSubmit = async (formValues: ISchoolRegistration) => {
    try {
      await mutate(formValues);
    } catch {
      throw new Error("Could not send contact form");
    }
  };

  if (isLoading) return null;
  if (isError) throw new Error("Could not send contact form");

  if (isSuccess && (response as RegistrationResponse).statusCode !== "errors") {
    return (
      <>
        <Box className={classes.success}>
          You are now registered, please log in here:
        </Box>
        <LoginForm />
      </>
    );
  }

  return (
    <>
      {isSuccess &&
        (response as RegistrationResponse).errors.map((error) => (
          <Box className={classes.error}>{error.message}</Box>
        ))}
      <InnerForm handleSubmit={handleSubmit} />
    </>
  );
};

export default RegistrationForm;
