import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";

import logoMain from "assets/vsc_logo.png";
import logoHome from "assets/vsc_logo_home.svg";
import logoSimple from "assets/vsc_logo_simple.png";

import logoRedWhite from "assets/gra_logo_red_white.png";
import logoRedBlack from "assets/gra_logo_red_black.png";
import logoWhite from "assets/gra_logo_white.png";

const useStyles = makeStyles<Theme, IProps>((props) => ({
  link: {
    flexGrow: (props) => (props.type === "simple" ? "initial" : 1),
  },
  logo: {
    marginTop: (props) => (props.type === "simple" ? 0 : "0.5rem"),
    width: (props) =>
      props.type === "simple"
        ? "100px"
        : props.type === "home"
        ? "150px"
        : "100px",
    [props.breakpoints.up("md")]: {
      width: (props) => (props.type === "simple" ? "8rem" : "10rem"),
    },
  },
}));

interface IProps {
  type: string;
}

const Logo = ({ type }: IProps) => {
  const classes = useStyles({ type: type } as IProps);
  // const logo =
  //   type === 'simple' ? logoSimple : type === 'home' ? logoHome : logoMain;
  const logo = type === "home" ? logoRedWhite : logoWhite;

  return (
    <Link to="/" className={classes.link}>
      <img src={logo} alt="GRA logo" className={classes.logo} />
    </Link>
  );
};

export default Logo;
