import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Grid,
  Select,
  FormControl,
  Button,
  Box,
  Checkbox,
  useMediaQuery,
} from '@material-ui/core';

import { PanelType, globalStyle } from 'resources/constants';
import { ISearchGames, IRating } from 'resources/interface';
import { fetchRatings, fetchPEGIGenres, fetchPEGIPlatforms } from 'resources/api';

import Content from 'panels/Content';
import Results from './Results';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    search: {
      marginBottom: '1.56rem',
    },
    age: {
      textAlign: 'center' as 'center',
      padding: '0.5rem',
      [breakpoints.up('md')]: {
        marginTop: '-5rem',
      },
    },
    orderText: {
      display: 'none',
      [breakpoints.up('md')]: {
        display: 'initial',
        lineHeight: '4.5rem',
      },
    },
    sort: {
      flex: 1,
      marginLeft: '2rem',
      backgroundColor: palette.common.white,
    },
  })
);

const Form = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { data:genres } = useQuery(["genres"],fetchPEGIGenres);
  const { data:platforms } = useQuery(["platforms"],fetchPEGIPlatforms);

  const {
    values: { gameTitle, genreId, platformId, sortBy },
    handleSubmit,
    handleChange,
    setFieldValue,
    submitForm,
    searchState,
    ratings,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Content type={PanelType.plainDark}>
        <Typography variant="h2" color="textSecondary">
          Search For Games
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={7} md={4}>
            <TextField
              name="gameTitle"
              label={isDesktop ? 'All or part of the game title' : 'Game title'}
              variant="filled"
              value={decodeURIComponent(gameTitle)}
              onChange={handleChange}
              required
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={5} md={8}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              //              disabled={!isValid}
            >
              {isDesktop ? 'Search For Games' : 'Search'}
            </Button>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              variant="outlined"
              style={{
                width: '100%',
              }}
            >
              {genres && (
                <Select
                  native
                  name="genreId"
                  value={genreId}
                  onChange={(e) => {
                    setFieldValue('genreId', e.target.value === '0' ? undefined : e.target.value, false);
                    submitForm();
                  }}
                  inputProps={{
                    name: 'genre',
                    id: 'genre',
                    style: { border: '1px solid white' },
                  }}
                >
                  <option value={0}>Select genre...</option>
                  {genres
                    .filter(({ genre_name }) => genre_name && genre_name.length)
                    .map(({ id, genre_name }) => (
                      <option key={id} value={id}>
                        {genre_name}
                      </option>
                    ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              {platforms && (
                <Select
                  native
                  name="platformId"
                  value={platformId}
                  onChange={(e) => {
                    setFieldValue('platformId', e.target.value === '0' ? undefined : e.target.value, false);
                    submitForm();
                  }}
                  inputProps={{
                    name: 'platform',
                    id: 'platform',
                    style: { border: '1px solid white' },
                  }}
                >
                  <option value={0}>Select platform...</option>
                  {platforms
                    .filter(({ platform_name }) => platform_name && platform_name.length)
                    .map(({ id, platform_name }) => (
                      <option key={id} value={id}>
                        {platform_name}
                      </option>
                    ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          {ratings && Array.isArray(ratings) && (
            <Grid item xs={12} md={4}>
              <Box display="flex">
                {(ratings as IRating[]).map(({ id, logo_image, title, age_category }) => (
                  <Box key={id} className={classes.age}>
                    <img src={logo_image} alt={title} width="100%" />
                    <Checkbox
                      name={age_category}
                      onChange={(e) => {
                        setFieldValue(age_category, e.target.checked, false);
                        submitForm();
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Content>
      <Content type={PanelType.smallTicks} hideGradient>
        <Box display="flex">
          <Typography variant="h2" color="textPrimary" style={{ flex: 2 }}>
            Search Results
          </Typography>
          <Typography variant="body1" color="textPrimary" className={classes.orderText}>
            Order by:
          </Typography>
          <FormControl variant="outlined" className={classes.sort}>
            <Select
              native
              name="sortBy"
              value={sortBy}
              onChange={(e) => {
                setFieldValue('sortBy', e.target.value, false);
                submitForm();
              }}
              inputProps={{
                name: 'order',
                id: 'order',
              }}
            >
              <option value="release_date">Most recent</option>
              <option value="game_title">Game title</option>
              <option value="age_category">Age rating</option>
            </Select>
          </FormControl>
        </Box>

        {ratings && platforms && <Results search={searchState} ratings={ratings} platforms={platforms} />}
      </Content>
    </form>
  );
};

const validationSchema = Yup.object({
  gameTitle: Yup.string().required('Enter all or part of a game title'),
  genreId: Yup.string(),
  platformId: Yup.string(),
  sortBy: Yup.string(),
});

interface IProps {
  search?: string;
  id?: string;
}
const Search = ({ search, id }: IProps) => {
  const { data: ratings } = useQuery(["ratings"],fetchRatings);
  const init: ISearchGames = {
    id: id,
    gameTitle: encodeURIComponent(search || ''),
  };
  const [searchState, setSearchState] = useState(init);
  const values = {
    gameTitle: encodeURIComponent(search || ''),
    genreId: '',
    platformId: '',
    sortBy: '',
  };

  const handleSubmit = async ({
    gameTitle,
    genreId,
    platformId,
    sortBy,
    ...rest
  }: {
    gameTitle: string;
    genreId: string;
    platformId: string;
    sortBy: string;
  }) => {
    if (ratings) {
      const ages: { [key: string]: any } = rest;
      const ageCategoryCsv = Object.keys(ages)
        .filter((key) => ages[key])
        .map((key) => key)
        .join(',');

      setSearchState({
        gameTitle: encodeURIComponent(gameTitle),
        ageCategoryCsv,
        genreId,
        platformId,
        sortBy,
      });
    }
  };

  return (
    <>
      {ratings && (
        <Formik
          render={(props) => <Form {...props} searchState={searchState} ratings={ratings} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default Search;
