import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';

import { PanelType } from 'resources/constants';

import Template from 'structure/Template';
import Courses from 'panels/Courses';
import Content from 'panels/Content';
import Download from 'panels/Download';
import More from 'panels/More';

const useStyles = makeStyles(({ breakpoints }) => ({
  more: {
    marginTop: '32px',
    [breakpoints.up('md')]: {
      marginTop: 'inherit',
    },
  },
}));

const Retail = () => {
  const classes = useStyles();

  return (
    <Template type="retail">
      <Courses type="retail" />
      <Box className={classes.more}>
        <More block="retail-panel-1" />
      </Box>
      <Content type={PanelType.plainRed}>
        <Typography variant="h4" align="center">
          Select your document
        </Typography>
        <Download type="retail" />
      </Content>
    </Template>
  );
};

export default Retail;
