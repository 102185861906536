import React, { PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { PanelType } from 'resources/constants';

import gradient from 'assets/back_gradient.png';
import gradientDark from 'assets/back_gradient_dark.png';
import smallTicks from 'assets/back_ticks.png';
import largeTick from 'assets/back_tick.png';

interface IStyleProps {
  hideGradient: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>(
  ({ palette, breakpoints }) => ({
    basic: {
      position: 'relative',
      padding: '20px',
      [breakpoints.up('md')]: {
        padding: '4rem',
      },
    },
    [PanelType.plain]: {
      color: `${palette.text.primary} !important`,
    },
    [PanelType.plainDark]: {
      backgroundColor: palette.primary.main,
      backgroundImage: `url(${gradientDark})`,
      backgroundSize: 'cover',
      color: palette.common.white,
      '& h4': {
        color: palette.common.white,
        textAlign: 'left',
      },
    },
    [PanelType.plainRed]: {
      backgroundColor: palette.primary.main,
      backgroundImage: `url(${gradient})`,
      backgroundPosition: 'right top',
      backgroundSize: 'cover',
      color: palette.common.white,
    },
    [PanelType.smallTicks]: {
      backgroundImage: (props) =>
        `url(${smallTicks})${props.hideGradient ? '' : `, url(${gradient})`}`,
      backgroundSize: 'contain',
      backgroundRepeat: 'repeat-y',
      backgroundPosition: 'right top',
    },
    [PanelType.smallTicksDark]: {
      backgroundImage: `url(${smallTicks}), url(${gradientDark})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat-y',
      backgroundPosition: 'right top',
      color: palette.common.white,
    },
    [PanelType.smallTicksRed]: {
      backgroundColor: palette.primary.main,
      backgroundImage: `url(${smallTicks})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'repeat-y',
      backgroundPosition: 'right top',
      color: palette.common.white,
    },
    [PanelType.largeTick]: {
      backgroundImage: `url(${largeTick}), url(${gradient})`,
      color: palette.common.white,
      backgroundSize: 'contain, cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
    },
    [PanelType.largeTickDark]: {
      backgroundImage: `url(${largeTick}), url(${gradientDark})`,
      color: palette.common.white,
      backgroundSize: 'contain, cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
    },
  })
);

interface IProps extends PropsWithChildren {
  type: PanelType;
  hideGradient?: boolean;
  className?: string;
}
const Content = ({
  type,
  hideGradient = false,
  className,
  children,
}: IProps) => {
  const classes = useStyles({ hideGradient: hideGradient });

  return (
    <Box className={`${classes.basic} ${classes[type]} ${className}`}>
      {children}
    </Box>
  );
};

export default Content;
