import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

import { PanelType } from 'resources/constants';

import Template from 'structure/Template';
import Hero from 'panels/Hero';
import Search from 'home/Search';
import More from 'panels/More';
import Content from 'panels/Content';

import LatestHub from 'home/LatestHub';
import LatestGames from 'home/LatestGames';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    marginTop: '-80px',
    [breakpoints.up('md')]: {
      marginTop: '-9.2rem',
    },
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <Template type="home">
      <Grid container className={classes.container}>
        <Grid item xs={12} md={8}>
          <Hero type="home" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Search />
        </Grid>
      </Grid>
      <More block="home-panel-1" details={{ link: 'parents' }} />
      <Content type={PanelType.largeTickDark}>
        <Grid container spacing={8}>
          <LatestHub />
          <LatestGames />
        </Grid>
      </Content>
    </Template>
  );
};

export default Home;
