import { useState, useEffect, useContext } from 'react';

//import { debugPlatform } from './utils';
import { VSCContext } from 'structure/Context';

export const useVSCContext = () => useContext(VSCContext);

let cachedScripts: Array<string> = [];

type TUseScript = (props: { src: string; dataAppKey?: string }) => Array<any>;
export const useScript: TUseScript = ({ src, dataAppKey }) => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedScripts.push(src);

      let script = document.createElement('script');
      script.src = src;
      if (dataAppKey) {
        script.setAttribute('data-app-key', dataAppKey);
        script.setAttribute('id', 'dropboxjs');
      }
      script.async = true;

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src]);

  return [state.loaded, state.error];
};
