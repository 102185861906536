import React, { useState } from "react";
import { Formik, Form, FormikProps } from "formik";

import {
  makeStyles,
  Box,
  TextField,
  Button,
  FormControl,
  Typography,
} from "@material-ui/core";

import { ISchoolPasswords } from "resources/interface";

import { useMutation } from "@tanstack/react-query";
import { postSchoolPasswords } from "resources/api";
import { useVSCContext } from "resources/hooks";
import { Navigate } from "react-router-dom";
import Content from "panels/Content";
import { PanelType } from "resources/constants";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  control: {
    width: "100%",
    [breakpoints.up("md")]: {
      width: "50rem",
    },
  },
  button: {
    margin: `${spacing(2)}px 0 ${spacing(1)}px 0`,
  },
}));

const ShowPasswords = () => {
  const classes = useStyles();
  const { mutate, data: response } = useMutation(postSchoolPasswords);

  const handleSubmit = async (formValues: ISchoolPasswords) => {
    try {
      await mutate(formValues);
    } catch {
      throw new Error("Could not send contact form");
    }
  };

  return (
    <>
      {response &&
        response.statusCode === "errors" &&
        response.errors.map((error: any) => <Box>{error.message}</Box>)}
      <Formik
        initialValues={{
          teacher_password: "",
          ks2_password: "",
          ks3_password: "",
          "18+_password": "",
        }}
        onSubmit={handleSubmit}
      >
        {(props: FormikProps<ISchoolPasswords>) => (
          <Form>
            <FormControl className={classes.control}>
              <TextField
                type="password"
                label="Teacher's password"
                name="teacher_password"
                variant="filled"
                margin="normal"
                value={props.values.teacher_password}
                onChange={props.handleChange}
              />
              <TextField
                type="password"
                label="KS2 password"
                name="ks2_password"
                variant="filled"
                margin="normal"
                value={props.values.ks2_password}
                onChange={props.handleChange}
              />
              <TextField
                type="password"
                label="KS3 password"
                name="ks3_password"
                variant="filled"
                margin="normal"
                value={props.values.ks3_password}
                onChange={props.handleChange}
              />
              <TextField
                type="password"
                label="18+ password"
                name="18+_password"
                variant="filled"
                margin="normal"
                value={props.values["18+_password"]}
                onChange={props.handleChange}
              />
            </FormControl>
            <Box>
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
              >
                Update Passwords
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

const Passwords = () => {
  const { userContext } = useVSCContext();

  if (userContext.statusCode === "") return null;
  if (userContext.statusCode === "not-logged-in")
    return <Navigate to="/schools" />;

  return (
    <Content type={PanelType.plainRed}>
      <Typography variant="h2" color="textSecondary">
        Update Your School Account Passwords
      </Typography>
      {userContext.statusCode === "ok" && (
        <Typography variant="body1" color="textSecondary">
          Thank you, passwords have been saved
        </Typography>
      )}
      <ShowPasswords />
    </Content>
  );
};

export default Passwords;
