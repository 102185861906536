import badlanguage from 'assets/content_badlanguage.png';
import discrimination from 'assets/content_discrimination.png';
import drugs from 'assets/content_drugs.png';
import fear from 'assets/content_fear.png';
import gambling from 'assets/content_gambling.png';
import horror from 'assets/content_horror.png';
import ingamepurchases from 'assets/content_ingamepurchases.png';
import onlinegameplay from 'assets/content_onlinegameplay.png';
import sex from 'assets/content_sex.png';
import violence from 'assets/content_violence.png';

export const descriptorIcons = [
  {
    id: 'Bad Language',
    src: badlanguage,
  },
  {
    id: 'Discrimination',
    src: discrimination,
  },
  {
    id: 'Drugs',
    src: drugs,
  },
  {
    id: 'Fear',
    src: fear,
  },
  {
    id: 'Gambling',
    src: gambling,
  },
  {
    id: 'Horror',
    src: horror,
  },
  {
    id: 'In-Game Purchases',
    src: ingamepurchases,
  },
  {
    id: 'Online Gameplay',
    src: onlinegameplay,
  },
  {
    id: 'Sex',
    src: sex,
  },
  {
    id: 'Violence',
    src: violence,
  },
];
