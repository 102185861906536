import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fetchSchoolCourses, fetchRetailCourses } from 'resources/api';
import { globalStyle, PanelType } from 'resources/constants';
import { useVSCContext } from 'resources/hooks';

import Content from 'panels/Content';
import Login from './Login';

const useStyles = makeStyles(({ palette }) =>
  Object.assign({}, globalStyle, {
    course: {
      color: palette.text.primary,
      backgroundColor: palette.common.white,
      padding: '2rem',
    },
    link: {
      textDecoration: 'none',
      marginTop: '2rem',
      display: 'block',
    },
  })
);

interface IProps {
  type: 'schools' | 'retail';
}
const ShowCourses = ({ type }: IProps) => {
  const classes = useStyles();
  const { data:payload } = useQuery(['courses'],
    type === 'schools' ? fetchSchoolCourses : fetchRetailCourses
  );

  if (!payload)
    return (
      <Grid container spacing={8} style={{ marginTop: '2rem' }}>
        <>
          <Grid item md={6}>
            <Box className={classes.course}>
              <CircularProgress
                style={{ display: 'block', margin: '0 auto' }}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box className={classes.course}>
              <CircularProgress
                style={{ display: 'block', margin: '0 auto' }}
              />
            </Box>
          </Grid>
        </>
      </Grid>
    );

  if (!payload.length)
    return (
      <Grid container spacing={8} style={{ marginTop: '2rem' }}>
        <Grid item md={6}>
          <Box className={classes.course}>
            <Typography variant="h4" gutterBottom color="primary">
              You have no courses assigned to you
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );

  return (
    <Grid container spacing={8} style={{ marginTop: '2rem' }}>
      {payload.map(
        ({
          id,
          course_title,
          summary_description,
          AttemptStatus,
          AttemptStatusName,
          SuccessStatusName,
          TotalPoints,
          CompletedInThePast,
        }) => {
          return (
            <Grid item xs={12} md={6} key={id}>
              <Box className={classes.course}>
                <Typography variant="h4" gutterBottom color="primary">
                  {course_title}
                </Typography>
                {/*<Typography variant="body1">{summary_description}</Typography>*/}
                <Typography variant="h6">
                  Status: <strong>{AttemptStatusName}{AttemptStatus === "2" && SuccessStatusName === 'Failed' && ' (Failed)'}</strong>
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Score: <strong>{TotalPoints}</strong>
                </Typography>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    {AttemptStatus === '2' && (
                      <a
                        href={`${process.env.REACT_APP_LMS_FOLDER}/lms_course_launcher.html?lmsaction=course-retake&cr_i=${id}`}
                        target="_blank" // eslint-disable-line
                        className={classes.link}
                      >
                        <Button variant="contained" color="secondary">
                          Restart
                        </Button>
                      </a>
                    )}
                    {AttemptStatus !== '2' && (
                      <a
                        href={`${process.env.REACT_APP_LMS_FOLDER}/lms_course_launcher.html?lmsaction=course-launch&cr_i=${id}`}
                        target="_blank" // eslint-disable-line
                        className={classes.link}
                      >
                        <Button variant="contained" color="secondary">
                          Launch
                        </Button>
                      </a>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {CompletedInThePast === '1' && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonRight}
                        style={{ marginTop: '2rem' }}
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_LMS_FOLDER}/course_certificate_print.html?cr_i=${id}`,
                            'VscCertPrint',
                            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600'
                          );
                          return false;
                        }}
                      >
                        Certificate
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

const Courses = ({ type }: IProps) => {
  const { userContext } = useVSCContext();
  const { setUser } = userContext;

  return (
    <>
      {userContext.statusCode === 'not-logged-in' && <Login type={type} />}
      {userContext.statusCode === 'ok' && (
        <Content
          type={
            type === 'retail' ? PanelType.smallTicksDark : PanelType.plainRed
          }
          hideGradient
        >
          <Typography variant="h2" gutterBottom color="textSecondary">
            Your Courses
          </Typography>
          <ShowCourses type={type} />
          <Box display="flex" style={{ marginTop: '2rem' }}>
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (setUser)
                  setUser({ statusCode: 'not-logged-in', setUser: setUser });
              }}
              style={{ marginLeft: '2rem' }}
            >
              Log Out
            </Button>
          </Box>
        </Content>
      )}
    </>
  );
};

export default Courses;
