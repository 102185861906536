import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Interweave } from "interweave";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Link, Typography, Grid } from "@material-ui/core";

import pages from "resources/pages";
import { useVSCContext } from "resources/hooks";
import { getBlock } from "resources/utils";

import Logo from "./Logo";
import Newsletter from "./Newsletter";
import Content from "panels/Content";
import { PanelType, globalStyle } from "resources/constants";

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    links: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap" as "wrap",
      marginBottom: "3rem",
    },
    linkBox: {
      display: "flex",
      flexDirection: "column" as "column",
      marginBottom: "3rem",
      [breakpoints.up("md")]: {
        marginBottom: "initial",
      },
    },
    linkleft: {
      textAlign: "center" as "center",
      [breakpoints.up("md")]: {
        textAlign: "right" as "right",
      },
    },
    linkright: {
      textAlign: "center" as "center",
      [breakpoints.up("md")]: {
        textAlign: "left" as "left",
      },
    },
    link: {
      color: palette.common.white,
      lineHeight: "1.5",
    },
    logo: {
      display: "none",
      [breakpoints.up("md")]: {
        display: "initial",
      },
    },
  })
);

interface IProps {
  type: string;
}
const Footer = ({ type }: IProps) => {
  const classes = useStyles();
  const { contentContext } = useVSCContext();
  const copyright = getBlock(contentContext, "footer-copyright");

  const FooterLinks = () => (
    <Box className={classes.links}>
      <Box className={classes.linkBox}>
        <Link
          component={RouterLink}
          to="/"
          variant="caption"
          className={`${classes.link} ${classes.upper}`}
        >
          Home
        </Link>
        <Link
          component={RouterLink}
          to="/games"
          variant="overline"
          className={classes.link}
        >
          Search Games
        </Link>
        <Link
          component={RouterLink}
          to="/contact"
          variant="overline"
          className={classes.link}
        >
          Get In Touch
        </Link>
      </Box>
      {pages
        .filter((page) => !page.menuHide)
        .map((page) => (
          <Box className={classes.linkBox} key={page.url}>
            <Link
              component={RouterLink}
              to={`/${page.url}`}
              variant="caption"
              className={`${classes.link} ${classes.upper}`}
            >
              {page.title}
            </Link>
            {pages
              .filter(
                (innerPage) =>
                  innerPage.menuHide &&
                  innerPage.url.includes(page.url) &&
                  innerPage.url !== "schools-accounts.html"
              )
              .map((innerPage) => (
                <Link
                  component={RouterLink}
                  to={`/${innerPage.url}`}
                  variant="overline"
                  className={classes.link}
                  key={innerPage.url}
                >
                  {innerPage.title}
                </Link>
              ))}
          </Box>
        ))}
    </Box>
  );

  return (
    <>
      {type !== "Xcontact" && (
        <Grid container spacing={2} style={{ padding: "4rem" }}>
          <Grid item xs={12} md={6} className={classes.linkleft}>
            <Link
              component={RouterLink}
              to="/contact"
              variant="h4"
              color="textPrimary"
            >
              Do you have a question?
            </Link>
          </Grid>
          <Grid item xs={12} md={6} className={classes.linkright}>
            <Link
              component={RouterLink}
              to="/contact"
              variant="h4"
              color="textPrimary"
            >
              <strong>Get in touch.</strong>
            </Link>
          </Grid>
        </Grid>
      )}
      <Box component="footer">
        <Content type={PanelType.plainRed}>
          <FooterLinks />
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box className={classes.logo}>
                <Logo type="simple" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="overline"
                className={classes.link}
                style={{ textAlign: "right", flexGrow: 1 }}
              >
                <Interweave content={copyright.content} />
              </Typography>
            </Grid>
          </Grid>
        </Content>
      </Box>
    </>
  );
};

export default Footer;
