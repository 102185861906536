import React, { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { errorMessages, PanelType, globalStyle } from 'resources/constants';

import Template from 'structure/Template';
import Content from 'panels/Content';

const useStyles = makeStyles(() => globalStyle);

interface IProps {
  type?: string;
}
const Error = ({ type = 'internal' }:IProps) => {
  const classes = useStyles();

  return (
    <Template type={`error-${type}`}>
      <Content type={PanelType.plain}>
        <Typography variant="h1" align="center">
          Oops!
        </Typography>
        <Typography variant="body1" align="center">
          {errorMessages[type].description}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/"
          className={classes.buttonCentred}
        >
          Go To Homepage
        </Button>
      </Content>
    </Template>
  );
};

export default Error;
