import React from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  video: {
    width: '100% !important',
    height: '30vh !important',
    [breakpoints.up('md')]: {
      height: '17rem !important',
    },
  },
}));

const Video = ({ url }: { url: string }) => {
  const classes = useStyles();

  if (!url) return null;

  return <ReactPlayer url={url} controls className={classes.video} />;
};

export default Video;
