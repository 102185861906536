import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { makeStyles, Box, Theme } from "@material-ui/core";

import { IArticle } from "resources/interface";

import { ReactComponent as FacebookIcon } from "assets/sm-facebook.svg";
import { ReactComponent as LinkedinIcon } from "assets/sm-linkedin.svg";
import { ReactComponent as TwitterIcon } from "assets/sm-twitter.svg";
import { ReactComponent as WhatsappIcon } from "assets/sm-whatsapp.svg";
import { ReactComponent as EmailIcon } from "assets/sm-email.svg";

interface IStyleProps {
  reduced: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>(({ breakpoints }) => ({
  social: {
    display: "flex",
    width: "fit-content",
    margin: (props) => (props.reduced ? "0 auto" : "1rem auto"),
    float: (props) => (props.reduced ? "right" : "inherit"),
  },
  button: {
    marginRight: (props) => (props.reduced ? "0" : "2rem"),
    "& svg": {
      verticalAlign: "middle",
      margin: "0 auto",
      [breakpoints.up("md")]: {
        marginLeft: (props) => (props.reduced ? "0.5rem" : "0"),
        marginRight: (props) => (props.reduced ? "0" : "1rem"),
      },
    },
  },
  icon: {
    width: "30px",
    height: "30px",
    [breakpoints.up("md")]: {
      width: "3rem",
      height: "3rem",
    },
  },
}));

interface IProps {
  article?: IArticle;
  link?: string;
  reduced?: boolean;
}
const SocialMedia = ({ article, link, reduced = false }: IProps) => {
  const classes = useStyles({ reduced: reduced });
  const { id, article_title } = article || {};
  const shareUrl = `${process.env.REACT_APP_HOST}${
    process.env.REACT_APP_BASENAME
  }${
    link
      ? link
      : `hub-article/${id}/${encodeURIComponent(
          (article_title || "article").replace(" ", "_")
        )}`
  }`;

  return (
    <Box className={classes.social}>
      <FacebookShareButton
        url={shareUrl}
        quote={article_title}
        className={classes.button}
      >
        <FacebookIcon className={classes.icon} />
        {!reduced && "Facebook"}
      </FacebookShareButton>
      <TwitterShareButton
        url={shareUrl}
        title={article_title}
        className={classes.button}
      >
        <TwitterIcon className={classes.icon} />
        {!reduced && "Twitter"}
      </TwitterShareButton>
      {!reduced && (
        <>
          <LinkedinShareButton url={shareUrl} className={classes.button}>
            <LinkedinIcon className={classes.icon} />
            LinkedIn
          </LinkedinShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={article_title}
            separator=":: "
            className={classes.button}
          >
            <WhatsappIcon className={classes.icon} />
            WhatsApp
          </WhatsappShareButton>
          <EmailShareButton
            url={shareUrl}
            subject={article_title}
            body="Have a look at this article on the Games Rating Authority website: "
            className={classes.button}
            style={{ marginRight: "0" }}
          >
            <EmailIcon className={classes.icon} />
            Email
          </EmailShareButton>
        </>
      )}
    </Box>
  );
};

export default SocialMedia;
