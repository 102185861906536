import React from 'react';
import { Typography } from '@material-ui/core';

import { PanelType } from 'resources/constants';

import Template from 'structure/Template';
import More from 'panels/More';
import Download from 'panels/Download';
import Content from 'panels/Content';

const AboutMedia = () => {
  return (
    <Template type="about-media">
      <More block="about-media-panel-1" />
      <Content type={PanelType.plainRed}>
        <Typography variant="h4" align="center">
          Select your document
        </Typography>
        <Download type="media-centre" />
      </Content>
    </Template>
  );
};

export default AboutMedia;
