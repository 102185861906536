import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fetchDownloads } from 'resources/api';
import { globalStyle } from 'resources/constants';

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    content: {
      marginTop: '2.6rem',
      display: 'flex',
      flexWrap: 'wrap' as 'wrap',
      justifyContent: 'center',
    },
    button: {},
    buttonBox: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      color: palette.common.white,
      width: '100px',
      [breakpoints.up('md')]: {
        width: '12rem',
        minHeight: '15rem',
      },
    },
    circle: {
      width: '100px',
      height: '100px',
      [breakpoints.up('md')]: {
        height: '9.37rem',
        width: '9.37rem',
      },
      backgroundColor: palette.common.white,
      borderRadius: '50%',
      display: 'inline-block',
      margin: '0 auto 20px auto',
    },
    title: {
      whiteSpace: 'normal' as 'normal',
    },
    schoolTitle: {
      whiteSpace: 'normal' as 'normal',
      color: palette.text.primary,
      marginTop: '30px',
      [breakpoints.up('md')]: {
        marginTop: '4rem',
      },
    },
  })
);

interface IProps {
  type: string;
}
const Download = ({ type }:IProps) => {
  const classes = useStyles();
  const { data:payload } = useQuery(['downloads',type],() => fetchDownloads(type));

  // useEffect(() => {
  //   query(type);
  // }, [type]);

  if (!payload) return null;

  return (
    <>
      <Box className={classes.content}>
        {payload.map((download, index) => (
          <Button
            href={download.document_file}
            target="_blank"
            key={index}
            className={classes.button}
          >
            <Box className={classes.buttonBox}>
              <Box className={classes.circle}>
                <img
                  src={download.link_image}
                  alt={download.title}
                  width="100%"
                />
              </Box>
              <Typography
                variant="caption"
                align="center"
                className={
                  type === 'schools-posters'
                    ? classes.schoolTitle
                    : classes.title
                }
              >
                {download.title}
              </Typography>
            </Box>
          </Button>
        ))}
      </Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.buttonCentred}
        href={`${process.env.REACT_APP_HOST}${
          process.env.REACT_APP_API_FOLDER
        }/doc-download.ashx?cr_i=${payload
          .map((download) => download.id)
          .join(',')}`}
        target="_blank"
      >
        Download All
      </Button>
    </>
  );
};

export default Download;
