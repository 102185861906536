import React, { Fragment, useEffect } from 'react';
import { Interweave } from 'interweave';
import { Box, Typography, Divider, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ISearchGames, IRating, IPEGIPlatform } from 'resources/interface';
import { searchGames } from 'resources/api';
import { descriptorIcons } from 'resources/images';

import SocialMedia from './SocialMedia';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  game: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255,0.6)',
    marginLeft: 'lrem',
    marginTop: '2rem',
  },
  image: {
    width: '100px',
    marginRight: '20px',
    [breakpoints.up('md')]: {
      width: '15rem',
      marginRight: '3rem',
    },
  },
  content: {
    flex: 1,
    color: palette.text.primary,
    textAlign: 'left',
    '& h4': {
      marginTop: '0.5rem',
    },
    '& h5': {
      margin: ' 1rem 0',
    },
  },
  ageLogo: {
    width: '40px',
    [breakpoints.up('md')]: {
      width: '7rem',
    },
    float: 'right',
  },
  descriptors: {
    border: `1px solid ${palette.common.black}`,
    marginTop: '2rem',
    width: 'fit-content',
  },
  descriptor: {
    width: '30px',
    height: '30px',
    [breakpoints.up('md')]: {
      width: '3.65rem',
      height: '3.65rem',
    },
    margin: '0.5rem',
  },
  supplement: {
    borderTop: `1px solid ${palette.common.black}`,
    padding: '0 2rem',
  },
  divider: {
    margin: '5rem 0',
    height: '3px',
  },
  link: {
    textDecoration: 'none',
    marginTop: '2rem',
    display: 'block',
  },
}));

interface IProps {
  search: ISearchGames;
  ratings: IRating[];
  platforms: IPEGIPlatform[];
}
const Results = ({ search, ratings, platforms }: IProps) => {
  const classes = useStyles();
  const { isLoading, error, data: payload, isFetching } = useQuery(["games", search], () => searchGames(search));

  // useEffect(() => {
  //   query(search);
  // }, [search]);

  if (!payload) return null;

  return (
    <>
      {(!Array.isArray(payload) || !payload.length) && (
        <Box className={classes.game}>
          <Box className={classes.content}>
            <Typography variant="h4" color="primary">
              No games found
            </Typography>
            <Typography variant="h6">Please try again with different search criteria</Typography>
          </Box>
        </Box>
      )}
      {Array.isArray(payload) &&
        payload.map(
          ({
            id,
            age_category,
            brief_outline,
            consumer_advice,
            cover_image_url,
            descriptorsCsv,
            descriptors_supplemental_text,
            game_title,
            genre_name,
            other_issues,
            platform_name,
            platform_id_raw,
            publisher_title,
            questionAnswersCsv,
            rating_issues,
            release_date,
            accessibility_url,
          }) => {
            const rating = ratings?.find((rating) => rating.age_category===age_category);
            const image = cover_image_url
              ? cover_image_url
              : platforms.find(({ id }) => id === platform_id_raw)?.platform_image;

            return (
              <Fragment  key={id}>
                <Box className={classes.game}>
                  <Box className={classes.image}>{image && <img src={image} alt={game_title} width="100%" />}</Box>
                  <Box className={classes.content}>
                    <Box display="flex" style={{ flexWrap: 'wrap' }}>
                      <Typography variant="h2" color="primary" gutterBottom style={{ flex: 2 }}>
                        {game_title}
                      </Typography>
                      <SocialMedia id={id} title={game_title} />
                    </Box>
                    {rating && <img src={rating.logo_image} alt={rating.title} className={classes.ageLogo} />}
                    <Typography variant="h6">
                      Platform: <strong>{platform_name}</strong>
                    </Typography>
                    <Typography variant="h6">
                      Publisher: <strong>{publisher_title}</strong>
                    </Typography>
                    <Typography variant="h6">
                      Released: <strong>{release_date}</strong>
                    </Typography>
                    {descriptorsCsv && (
                      <Box className={classes.descriptors}>
                        <Box display="flex">
                          {descriptorsCsv.split(', ').map((descriptor: string) => (
                            <img
                              src={descriptorIcons.find((icon) => icon.id === descriptor.trim())?.src}
                              title={descriptor}
                              alt={descriptor}
                              key={descriptor}
                              className={classes.descriptor}
                            />
                          ))}
                        </Box>
                        {descriptors_supplemental_text && (
                          <Box className={classes.supplement}>
                            <Typography variant="overline">{descriptors_supplemental_text}</Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    <Typography variant="h5" gutterBottom style={{ marginTop: '4rem' }}>
                      Advice for consumers
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <Interweave content={consumer_advice} />
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Brief outline of the game
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <Interweave content={brief_outline} />
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Content-specific issues
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <Interweave content={rating_issues} />
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Secondary issues/information
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <Interweave content={other_issues} />
                    </Typography>
                    {accessibility_url && (
                      <>
                        <Typography variant="h5" gutterBottom>
                          Accessibility Information
                        </Typography>
                        <a
                          href={accessibility_url}
                          target="_blank" // eslint-disable-line
                          className={classes.link}
                        >
                          <Button variant="contained">View accessibility report</Button>
                        </a>
                      </>
                    )}
                  </Box>
                </Box>
                <Divider className={classes.divider} />
              </Fragment>
            );
          }
        )}
    </>
  );
};

export default Results;
