import React from 'react';

import Template from 'structure/Template';
import Collaborations from 'about/Collaborations';

const AboutCollaborations = () => {
  return (
    <Template type="about-collaborations">
      <Collaborations />
    </Template>
  );
};

export default AboutCollaborations;
