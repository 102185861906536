import React from 'react';

import Template from 'structure/Template';
import Passwords from 'schools/Passwords';

const Schools = () => {
  return (
    <Template type="schools">
      <Passwords />
    </Template>
  );
};

export default Schools;
