export const googleAnalytics = "UA-33094020-1";

type IErrorMessage = {
  title: string;
  description: string;
};
type IErrorMessages = {
  [key: string]: IErrorMessage;
};

export const errorMessages: IErrorMessages = {
  "404": {
    title: "",
    description:
      "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable",
  },
  internal: {
    title: "",
    description: "The GRA have been informed of the issue, please try again",
  },
};

export enum PanelType {
  plain,
  plainDark,
  plainRed,
  smallTicks,
  smallTicksDark,
  smallTicksRed,
  largeTick,
  largeTickDark,
}

/* Styles */

export const globalStyle = {
  buttonCentred: {
    display: "block",
    margin: "0 auto",
    minWidth: "15rem",
  },
  buttonRight: {
    float: "right" as "right",
    marginLeft: "2rem",
  },
  overlay: {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: "0.4rem",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2,
    margin: "14px",
  },
  clickable: {
    cursor: "pointer",
  },
  upper: {
    textTransform: "uppercase" as "uppercase",
  },
};
