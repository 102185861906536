import React, { useState, ReactNode, FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import pages from "resources/pages";
import { fetchAuth, fetchContent } from "resources/api";

import { VSCContext, defaultContent, defaultUser } from "./Context";
import AppBar from "./AppBar";
import Menu from "./Menu";
import Footer from "./Footer";
import Hero from "panels/Hero";
import { IUser } from "resources/interface";

export const colourVSCRed = "#E3283B";
export const colourVSCGrey = "#8f9194";

const breakpoints = createBreakpoints({});

const theme = (createTheme as any)({
  overrides: {
    MuiButton: {
      root: {
        lineHeight: "32px",
        textAlign: "center",
        padding: "10px",
        width: "100%",
        [breakpoints.up("md")]: {
          lineHeight: "3.3rem",
          padding: "0 2rem",
          width: "fit-content",
        },
      },
    },
  },
  props: {
    MuiTextField: {
      style: {
        flex: 1,
        backgroundColor: "#fff",
        borderRadius: "5px",
      },
    },
    MuiFilledInput: {
      style: {
        backgroundColor: "#fff",
      },
    },
    MuiInputLabel: {
      style: {
        color: "#000",
      },
    },
  },
  palette: {
    primary: {
      main: colourVSCRed,
    },
    secondary: {
      main: colourVSCGrey,
    },
    text: {
      primary: "#000", // colourVSCGrey,
      secondary: "#fff",
    },
  },
  typography: {
    h1: {
      fontSize: "3em",
      fontWeight: 300,
      marginBottom: "2.08rem",
    },
    h2: {
      fontSize: "2.5em",
      fontWeight: 300,
      marginBottom: "1.5rem",
      color: colourVSCRed,
    },
    h3: {
      fontSize: "2.08em",
      fontWeight: 500,
    },
    h4: {
      fontSize: "2.08em",
      fontWeight: 300,
    },
    h5: {
      fontSize: "1.88em",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1.56em",
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: "1.56em",
      fontWeight: 400,
      lineHeight: "1.2",
    },
    body1: {
      fontSize: "1.15em",
      fontWeight: 400,
    },
    body2: {
      fontSize: "1.15em",
      fontWeight: 500,
    },
    caption: {
      fontSize: "1.04em",
      fontWeight: 700,
      //      textTransform: 'uppercase',
    },
    button: {
      fontSize: "1.15em",
      fontWeight: 500,
      textTransform: "initial",
      whiteSpace: "nowrap",
    },
    overline: {
      fontSize: "0.94em",
      fontWeight: 400,
      textTransform: "initial",
    },
  },
});

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    width: "100%",
    maxWidth: "1920px",
    minHeight: "100vh",
    margin: "0 auto",
    color: theme.palette.text.primary,
    fontSize: "12px",
    [breakpoints.up("md")]: {
      fontSize: "1vw",
    },
    [breakpoints.up("xl")]: {
      fontSize: "19.2px",
    },
  },
  main: {
    backgroundColor: theme.palette.common.white,
  },
}));

interface IProps {
  type: string;
  children: ReactNode;
}
const Template: FC<IProps> = ({ type, children }) => {
  const { data: payloadAuth } = useQuery(["auth"], fetchAuth);
  const { data: payloadContent } = useQuery(["content"], fetchContent);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState<IUser>(defaultUser);
  const title = pages.find((page) => page.url === type)?.title;

  useEffect(() => {
    if (payloadAuth) {
      const storedUser = localStorage && localStorage.getItem("user");
      setUser(
        storedUser?.length && payloadAuth.length
          ? JSON.parse(storedUser)
          : { statusCode: "not-logged-in" }
      );
    }
  }, [payloadAuth]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUser = (newUser: IUser) => {
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
    if (newUser.statusCode === "not-logged-in") window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <VSCContext.Provider
        value={{
          contentContext: payloadContent || defaultContent,
          userContext: { ...user, setUser: handleUser },
          refresh: user.statusCode === "ok",
        }}
      >
        <div className={classes.root}>
          <Helmet>
            <title>{title ? `${title} | ` : ""}Games Rating Authority</title>
            <link
              rel="canonical"
              href={`https://gamesratingauthority.org.uk${window.location.pathname}`}
            />
          </Helmet>
          <CssBaseline />
          <Menu isOpen={mobileOpen} handleClick={handleDrawerToggle} />
          <AppBar handleClick={handleDrawerToggle} type={type} />
          <Box component="main" className={classes.main}>
            {type !== "home" && <Hero type={type} />}
            {children}
          </Box>
          <Footer type={type} />
        </div>
      </VSCContext.Provider>
    </ThemeProvider>
  );
};

export default Template;
