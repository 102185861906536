import React from 'react';

import Template from 'structure/Template';

import More from 'panels/More';
import MoreImage from 'panels/MoreImage';
import YetMore from 'about/More';

const About = () => {
  return (
    <Template type="about">
      <More block="about-panel-1" />
      <MoreImage block="about-panel-2" url="about-team" />
      <More
        block="about-panel-3"
        details={{
          link: 'about-history',
          linkLabel: 'Learn About Us',
        }}
      />
      <YetMore />
    </Template>
  );
};

export default About;
