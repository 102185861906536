import React, { FC, forwardRef } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import {
  makeStyles,
  AppBar as MUIAppBar,
  Toolbar,
  IconButton,
  Container,
  List,
  ListItem,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import pages from 'resources/pages';

import Logo from './Logo';
//import CTA from 'panels/CTA';

import gradient from 'assets/back_gradient.png';

interface IStyleProps {
  type?: string;
}
const useStyles = makeStyles<Theme, IStyleProps>(
  ({ palette, breakpoints }) => ({
    appBar: {
      boxShadow: 'none',
      backgroundColor: (props) =>
        props.type === 'home' ? 'transparent' : palette.primary.main,
      backgroundImage: (props) =>
        props.type === 'home' ? 'none' : `url(${gradient})`,
      backgroundSize: 'cover',
    },
    container: {
      flexGrow: 1,
      padding: '0 20px',
      [breakpoints.up('md')]: {
        padding: '0.1rem 4rem',
      },
    },
    mobile: {
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menu: {
      display: 'none',
      [breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    menuItem: {
      '& span': {
        fontSize: '1.04em',
        fontWeight: 700,
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        color: palette.common.white,
      },
      marginTop: 0,
      '&:hover': {},
    },
    icon: {
      color: palette.common.white,
    },
  })
);

interface IProps {
  type: string;
  handleClick: () => void;
}
const AppBar: FC<IProps> = ({ type, handleClick }) => {
  const classes = useStyles({ type: type });
  //  const trigger = useScrollTrigger();

  return (
    <MUIAppBar position="relative" className={classes.appBar}>
      {/*<CTA />*/}
      <Container maxWidth="xl" className={classes.container}>
        <Toolbar disableGutters>
          <Logo type={type} />
          <List className={classes.menu}>
            {pages
              .filter(({ menuHide }) => !menuHide)
              .map((page) => (
                <ListItem
                  button
                  key={page.url}
                  component={forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
                    (props, ref) => (
                      <RouterLink
                        to={`/${page.url}`}
                        {...props}
                        ref={ref as any}
                      />
                    )
                  )}
                >
                  <ListItemText
                    primary={page.title}
                    className={classes.menuItem}
                  />
                </ListItem>
              ))}
          </List>
          <IconButton
            edge="end"
            className={`${classes.icon} ${classes.mobile}`}
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
    </MUIAppBar>
  );
};

export default AppBar;
