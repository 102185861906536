import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useVSCContext } from 'resources/hooks';
import { getBlock } from 'resources/utils';
import { PanelType } from 'resources/constants';

import Content from 'panels/Content';
import { Interweave } from 'interweave';

const More = () => {
  const { contentContext } = useVSCContext();
  const panel1 = getBlock(contentContext, 'contact-panel-2');
  const panel2 = getBlock(contentContext, 'contact-panel-3');

  return (
    <Content type={PanelType.plainRed}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {panel1.title}
          </Typography>
          <Typography variant="body1">
            <Interweave content={panel1.content} />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {panel2.title}
          </Typography>
          <Typography variant="body1">
            <Interweave content={panel2.content} />
          </Typography>
        </Grid>
      </Grid>
    </Content>
  );
};

export default More;
