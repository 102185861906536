import React from 'react';

import Template from 'structure/Template';
import Courses from 'panels/Courses';
import Download from 'schools/Download';

const Schools = () => {
  return (
    <Template type="schools">
      <Courses type="schools" />
      <Download />
    </Template>
  );
};

export default Schools;
