import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PanelType, globalStyle } from 'resources/constants';
import { fetchRatings } from 'resources/api';

import Content from 'panels/Content';
import { Interweave } from 'interweave';

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    gridItem: {
      position: 'relative' as 'relative',
      marginTop: '2.6rem',
      '& img': {
        width: '100%',
      },
      '&:hover': {
        '& div': {
          display: 'none',
        },
      },
    },
    content: {
      marginTop: '2.6rem',
      backgroundColor: palette.common.white,
      color: palette.text.primary,
      padding: '2.6rem',
    },
    description: {
      '& img': {
        maxWidth: '100%',
        [breakpoints.up('md')]: {
          maxWidth: 'inherit',
        },
      },
    },
  })
);

const Explore = () => {
  const classes = useStyles();
  const { data:payload } = useQuery(['ratings'],fetchRatings);
  const [chosen, setChosen] = useState('');

  if (!payload || !Array.isArray(payload)) return null;

  return (
    <Content type={PanelType.smallTicksDark}>
      <Typography variant="h4" align="center">
        Explore the PEGI age ratings
      </Typography>
      <Grid container spacing={4}>
        {payload.map((rating) => (
          <Grid item xs={2} className={classes.gridItem} onClick={() => setChosen(rating.id)}>
            <img src={rating.logo_image} alt={rating.title} />
            <div className={classes.overlay} />
          </Grid>
        ))}
      </Grid>
      {payload
        .filter((rating) => rating.id === chosen)
        .map((rating) => (
          <Box className={classes.content}>
            <Typography variant="body2">{rating.title}</Typography>
            <Typography variant="body1" className={classes.description}>
              <Interweave content={rating.description} />
            </Typography>
          </Box>
        ))}
    </Content>
  );
};

export default Explore;
