import React, { useState } from 'react';
import { Interweave } from 'interweave';
import { Typography } from '@material-ui/core';

import { PanelType } from 'resources/constants';
import { getBlock } from 'resources/utils';
import { useVSCContext } from 'resources/hooks';
import { IRestriction } from 'resources/interface';

import Content from 'panels/Content';
import ControlsRestrict from './ControlsRestrict';
import ControlsPlatform from './ControlsPlatform';

const Controls = () => {
  const { contentContext } = useVSCContext();
  const [restriction, setRestriction] = useState<IRestriction | undefined>();
  const tips = getBlock(contentContext, `parents-controls-tips`);

  return (
    <Content type={PanelType.largeTickDark}>
      <Typography variant="h2" color="textSecondary" align="center">
        What would you like to restrict?
      </Typography>
      <ControlsRestrict
        handleSelect={setRestriction}
        restriction={restriction}
      />
      {restriction && (
        <Content type={PanelType.plain}>
          <Typography variant="h2" align="center" color="textSecondary">
            Select your console
          </Typography>
          <ControlsPlatform restriction={restriction} />
        </Content>
      )}
      <Typography variant="h4" gutterBottom>
        Tips for parents
      </Typography>
      <Typography variant="body1">
        <Interweave content={tips.content} />
      </Typography>
    </Content>
  );
};

export default Controls;
