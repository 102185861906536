import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchArticles } from 'resources/api';

import Template from 'structure/Template';
import Latest from 'hub/Latest';
import Article from 'hub/Article';
import { useParams } from 'react-router-dom';

const HubArticle = () => {
  const { data:payload } = useQuery(['articles'],fetchArticles);
  const {id} = useParams();

  if (!payload || !id) return null;
  const article = payload.find((article) => article.id === id);
  if (!article) return null;

  return (
    <Template type="hub_article">
      <Article article={article} />
      <Latest id={id} articles={payload} />
    </Template>
  );
};

export default HubArticle;