import React from 'react';

import Template from 'structure/Template';

import More from 'panels/More';
import Team from 'about/Team';

const AboutTeam = () => {
  return (
    <Template type="about-team">
      <More block="about-team-panel-1" />
      <Team />
    </Template>
  );
};

export default AboutTeam;
