import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { makeStyles, Button, TextField, Box } from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";

const useStyles = makeStyles(({ palette, spacing }) => ({
  search: {
    display: "flex",
    marginBottom: "1.56rem",
  },
  button: {
    border: "1px solid white",
    padding: "0 !important",
    marginLeft: "2rem",
    width: "fit-content !important",
    "& svg": {
      fill: palette.common.white,
      fontSize: "3em",
    },
  },
}));

const validationSchema = Yup.object({
  search: Yup.string().required("Enter all or part of a game title"),
});

const Form = (props: any) => {
  const classes = useStyles();

  const {
    values: { search },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.search}>
        <TextField
          name="search"
          helperText={touched.search ? errors.search : ""}
          label="Enter product title"
          value={search}
          onChange={handleChange}
          required
          variant="filled"
        />
        <Button type="submit" disabled={!isValid} className={classes.button}>
          <SearchIcon />
        </Button>
      </Box>
    </form>
  );
};

const SearchForm = () => {
  const values = { search: "" };
  const [redirect, setRedirect] = useState("");

  const submit = async ({ search }: { search: string }) => {
    setRedirect(search);
  };

  if (redirect.length) {
    return <Navigate to={`/games/?q=${encodeURIComponent(redirect)}`} />;
  }

  return (
    <Formik
      render={(props) => <Form {...props} />}
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={submit}
    />
  );
};

export default SearchForm;
