import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Interweave } from 'interweave';
import { Typography } from '@material-ui/core';

import { fetchControls } from 'resources/api';
import { PanelType } from 'resources/constants';

import Template from 'structure/Template';

import Content from 'panels/Content';
import ControlsRestrict from 'parents/ControlsRestrict';
import ControlsPlatform from 'parents/ControlsPlatform';
import ParentsControls from './ParentsControls';

const ParentsControlsArticle = () => {
  const {
    restrictionTypeId,
    platformNameId,
    restrictionType,
    platformName,
  } = useParams();
  const { data:payload } = useQuery(['controls'],()=>fetchControls({
    platformNameId,
    restrictionTypeId,
  }));

  // useEffect(() => {
  //   if (platformNameId && restrictionTypeId) {
  //     query({
  //       platformNameId,
  //       restrictionTypeId,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [platformNameId, restrictionTypeId]);

  if (!restrictionTypeId || !restrictionType) return <ParentsControls />;

  return (
    <Template type="parents-controls-article">
      <ControlsRestrict restriction={{
          id: restrictionTypeId,
          restriction_name: restrictionType,
        }} platform={{
          id: platformNameId,
          platform_name: platformName,
        }} />
      <Content type={PanelType.smallTicks} hideGradient>
        {payload && !!payload.length && (
          <Typography variant="body1" color="textPrimary">
            <Interweave content={payload[0].instructions_copy}/>
          </Typography>
        )}
        <Content type={PanelType.plain}>
          <Typography variant="h2" align="center" color="primary">
            Change your console
          </Typography>
          <ControlsPlatform restriction={{
          id: restrictionTypeId,
          restriction_name: restrictionType,
        }} platform={{
          id: platformNameId,
          platform_name: platformName,
        }} />
        </Content>
      </Content>
    </Template>
  );
};

export default ParentsControlsArticle;
