import React from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";

import Template from "structure/Template";
import Search from "games/Search";

const Games = () => {
  const { id } = useParams();
  // eslint-disable-next-line
  const { q: search } = queryString.parse(location.search);

  console.log(">>> search: ", search);

  return (
    <Template type="games">
      <Search search={search as string} id={id} />
    </Template>
  );
};

export default Games;
