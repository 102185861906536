import React, { useState } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  makeStyles,
  Button,
  TextField,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { ILogin, RegistrationResponse } from "resources/interface";
import { useMutation } from "@tanstack/react-query";
import { postLogin } from "resources/api";
import { useVSCContext } from "resources/hooks";

const useStyles = makeStyles(({ breakpoints }) => ({
  login: {
    marginBottom: "10px",
    [breakpoints.up("md")]: {
      marginBottom: "inherit",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "10px",
    [breakpoints.up("md")]: {
      width: "90%",
      marginBottom: "inherit",
    },
  },
  button: {
    width: "100%",
    [breakpoints.up("md")]: {
      padding: "0 3rem !important",
    },
  },
  error: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "white",
    color: "red",
    padding: "10px",
    marginBottom: "30px",
  },
}));

const validationSchema = Yup.object({
  email: Yup.string().required("Username or email address required"),
  pass: Yup.string().required("Enter your password"),
});

const Form = (props: any) => {
  const classes = useStyles();

  const {
    values: { email, pass },
    errorMsg,
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
  } = props;

  return (
    <form onSubmit={handleSubmit} style={{ flex: "2" }}>
      <Grid container className={classes.login}>
        <Grid item xs={12} md={4}>
          <TextField
            name="email"
            helperText={touched.email ? errors.email : ""}
            error={Boolean(errors.email)}
            label="Username"
            value={email}
            onChange={handleChange}
            required
            variant="filled"
            className={classes.textField}
            style={{ flex: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="pass"
            helperText={touched.pass ? errors.pass : ""}
            error={Boolean(errors.pass)}
            label="Password"
            type="password"
            value={pass}
            onChange={handleChange}
            required
            variant="filled"
            className={classes.textField}
            style={{ flex: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={!isValid}
            style={{ flex: 1 }}
          >
            Sign in
          </Button>
        </Grid>
        {errorMsg && (
          <Grid item xs={12}>
            <Typography variant="body1">{errorMsg}</Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const LoginForm = () => {
  const classes = useStyles();
  const { userContext } = useVSCContext();
  const { setUser } = userContext;
  const {
    mutate,
    isError,
    isLoading,
    isSuccess,
    data: payload,
  } = useMutation(postLogin);

  const values = { email: "", pass: "" };

  const submit = async (formValues: ILogin) => {
    try {
      await mutate(formValues);
    } catch {
      throw new Error("Could not sign in");
    }
  };

  if (isLoading) return <Box>Logging in, please wait....</Box>;
  if (isError) throw new Error("Could not sign in");

  if (isSuccess && (payload as RegistrationResponse).statusCode === "ok")
    setUser(payload);

  return (
    <>
      {isSuccess &&
        (payload as RegistrationResponse).statusCode === "bad-auth" && (
          <Box className={classes.error}>
            Login details not recognised, please try again
          </Box>
        )}
      <Formik
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props: FormikProps<ILogin>) => <Form {...props} />}
      </Formik>
    </>
  );
};

export default LoginForm;
