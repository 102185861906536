import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Interweave } from 'interweave';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fetchCollaborations } from 'resources/api';
import { PanelType, globalStyle } from 'resources/constants';

import Content from 'panels/Content';

const useStyles = makeStyles(({ palette }) =>
  Object.assign({}, globalStyle, {
    box0: {
      padding: '2rem',
    },
    image0: {
      width: '100%',
      border: `0.5rem solid ${palette.common.white}`,
    },
    box1: {
      padding: '7.21rem',
      backgroundColor: palette.common.white,
      margin: '0 -4rem',
    },
    image1: {
      width: '100%',
      border: `0.5rem solid ${palette.text.primary}`,
    },
  })
);

const Collaborations = () => {
  const classes = useStyles();
  const { isLoading, error, data: payload, isFetching } = useQuery(["collaborations"], fetchCollaborations);

  if (!payload) return null;

  return (
    <Content type={PanelType.smallTicks} hideGradient>
      {payload.map((collaborator) => (
        <Grid container spacing={4}>
          <Grid item md={4}>
            <img
              src={collaborator.image}
              alt={collaborator.title}
              className={classes.image0}
            />
          </Grid>
          <Grid item md={8}>
            <Typography variant="h4" color="primary" gutterBottom>
              {collaborator.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Interweave content={collaborator.description} />
            </Typography>
            {collaborator.link_url && (
              <Button
                variant="contained"
                color="primary"
                component="a"
                href={collaborator.link_url}
                target="_blank"
                className={classes.buttonRight}
              >
                Find Out More
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
    </Content>
  );
};

export default Collaborations;
