import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Interweave } from 'interweave';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Grid } from '@material-ui/core';

import { globalStyle, PanelType } from 'resources/constants';
import { useVSCContext } from 'resources/hooks';
import { getBlock } from 'resources/utils';

import Content from 'panels/Content';
import Video from 'panels/Video';

//import { ReactComponent as GooglePlay } from 'assets/google_play.svg';
//import { ReactComponent as AppStore } from 'assets/app_store.svg';

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    item: {
      alignSelf: 'center',
    },
    box: {
      textAlign: 'center' as 'center',
      marginBottom: '1rem',
      [breakpoints.up('md')]: {
        minHeight: '20rem',
      },
    },
    title: {
      color: palette.common.white,
    },
    icon: {
      width: '16rem',
      height: 'auto',
      marginTop: '2rem',
    },
    image: {
      width: '100%',
      [breakpoints.up('md')]: {
        width: 'auto',
        height: '17rem',
      },
    },
    link: {
      textDecoration: 'none',
    },
    content: {
      minHeight: '7rem',
    },
  })
);

const More = () => {
  const classes = useStyles();
  const { contentContext } = useVSCContext();
  const panel2 = getBlock(contentContext, 'parents-panel-2');
  const panel3 = getBlock(contentContext, 'parents-panel-3');

  return (
    <Content type={PanelType.plainRed}>
      <Grid container spacing={4}>
        <Grid item className={classes.item} xs={12} md={6}>
          {panel2.video && (
            <Box className={classes.box}>
              <Video url={panel2.video} />
            </Box>
          )}
          <Typography variant="h2" align="center" className={classes.title}>
            {panel2.title}
          </Typography>
          <Typography variant="body1" className={classes.content}>
            <Interweave content={panel2.content} />
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/parents-safety"
            color="secondary"
            className={classes.buttonCentred}
          >
            Tell Me More
          </Button>
        </Grid>
        <Grid item className={classes.item} xs={12} md={6}>
          <Box className={classes.box}>
            <img
              src={panel3.image}
              alt={panel3.title}
              className={classes.image}
            />
          </Box>
          <Typography variant="h2" align="center" className={classes.title}>
            {panel3?.title}
          </Typography>
          <Typography variant="body1" className={classes.content}>
            <Interweave content={panel3.content} />
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <a
                href="https://play.google.com/store/apps/details?id=net.modezero.pegi_ratings&hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonCentred}
                >
                  Google Play
                </Button>
                {/*<GooglePlay className={classes.icon} />*/}
              </a>
            </Grid>
            <Grid item xs={12} md={6}>
              <a
                href="https://apps.apple.com/gb/app/pegi-ratings/id1419963034"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonCentred}
                >
                  App Store
                </Button>
                {/*<AppStore className={classes.icon} />*/}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Content>
  );
};

export default More;
