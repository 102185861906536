import React from 'react';

import Template from 'structure/Template';
import More from 'panels/More';
import YetMore from 'home/More';

const Contact = () => {
  return (
    <Template type="contact">
      <More block="contact-panel-1" useMap />
      <YetMore />
    </Template>
  );
};

export default Contact;
