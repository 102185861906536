import React, { useEffect, useState } from "react";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { searchGames, fetchRatings, fetchPEGIPlatforms } from "resources/api";
import { globalStyle } from "resources/constants";
import { descriptorIcons } from "resources/images";

import { ReactComponent as ChevronRight } from "assets/chevron.svg";
import { useQuery } from "@tanstack/react-query";

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    gridItem: {
      position: "relative" as "relative",
      textAlign: "center" as "center",
    },
    game: {
      display: "flex" as "flex",
      backgroundColor: palette.common.white,
      padding: "2rem",
      marginLeft: "lrem",
      marginBottom: "2rem",
      "&:last-of-type": {
        marginBottom: "8rem",
      },
      minHeight: "125px",
      [breakpoints.up("md")]: {
        minHeight: "13rem",
      },
    },
    image: {
      marginRight: "2rem",
      width: "90px",
      [breakpoints.up("md")]: {
        width: "8rem",
      },
    },
    title: {
      lineHeight: "15pt",
      [breakpoints.up("md")]: {
        lineHeight: "1.56rem",
      },
    },
    content: {
      color: palette.text.primary,
      textAlign: "left" as "left",
      width: "100%",
    },
    info: {
      overflow: "hidden",
    },
    descriptor: {
      marginTop: "0.5rem",
      marginRight: "0.5rem",
      width: "30px",
      height: "30px",
      [breakpoints.up("md")]: {
        width: "2rem",
        height: "2rem",
      },
    },
    age: {
      width: "min-content",
      position: "relative" as "relative",
    },
    ageLogo: {
      width: "40px",
      [breakpoints.up("md")]: {
        width: "3.13rem",
      },
    },
    chevron: {
      position: "absolute" as "absolute",
      bottom: 0,
      right: 0,
      width: "30px",
      height: "30px",
      [breakpoints.up("md")]: {
        width: "2rem",
        height: "2.5rem",
      },
    },
    button: {
      [breakpoints.up("md")]: {
        position: "absolute" as "absolute",
        bottom: "2rem",
        left: "10rem",
        width: "14rem !important",
      },
    },
  })
);

const LatestGames = () => {
  const classes = useStyles();
  const {
    isLoading,
    error,
    data: payload,
    isFetching,
  } = useQuery(["games"], () =>
    searchGames({
      sortBy: "release_date",
      pageSize: "3",
      pageNumber: "1",
    })
  );
  const { data: ratings } = useQuery(["ratings"], fetchRatings);
  const { data: platforms } = useQuery(["platforms"], fetchPEGIPlatforms);
  const [redirect, setRedirect] = useState("");

  // useEffect(() => {
  //   query({
  //     sortBy: 'release_date',
  //     pageSize: '3',
  //     pageNumber: '1',
  //   });
  //   // eslint-disable-next-line
  // }, []);

  if (redirect.length) {
    return <Navigate to={`/games/?q=${encodeURIComponent(redirect)}`} />;
  }

  if (!payload || !platforms || !ratings || !Array.isArray(ratings))
    return null;

  return (
    <Grid item xs={12} md={6} className={classes.gridItem}>
      <Typography variant="h2" color="textSecondary">
        Recent releases
      </Typography>
      {payload.map(
        ({
          age_category,
          cover_image_url,
          descriptorsCsv,
          game_title,
          id,
          platform_name,
          platform_id_raw,
          publisher_title,
          release_date,
        }) => {
          const rating = ratings.find((rating) =>
            rating.title.includes(age_category)
          );
          const image = cover_image_url
            ? cover_image_url
            : platforms.find(({ id }) => id === platform_id_raw)
                ?.platform_image;

          return (
            <Box
              className={`${classes.game} ${classes.clickable}`}
              onClick={() => {
                setRedirect(game_title);
              }}
              key={id}
            >
              <Box className={classes.image}>
                {image && <img src={image} alt={game_title} width="100%" />}
              </Box>
              <Box className={classes.content}>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.title}
                  gutterBottom
                >
                  {game_title}
                </Typography>
                <Typography variant="body1" className={classes.info}>
                  Platform: <strong>{platform_name}</strong>
                </Typography>
                <Typography variant="body1" className={classes.info}>
                  Publisher: <strong>{publisher_title}</strong>
                </Typography>
                <Typography variant="body1" className={classes.info}>
                  Released: <strong>{release_date}</strong>
                </Typography>
                <Box display="flex">
                  {descriptorsCsv &&
                    descriptorsCsv
                      .split(", ")
                      .map((descriptor: string) => (
                        <img
                          src={
                            descriptorIcons.find(
                              (icon) => icon.id === descriptor.trim()
                            )?.src
                          }
                          title={descriptor}
                          alt={descriptor}
                          key={descriptor}
                          className={classes.descriptor}
                        />
                      ))}
                </Box>
              </Box>
              <Box className={classes.age}>
                {rating && (
                  <img
                    src={rating.logo_image}
                    alt={rating.title}
                    className={classes.ageLogo}
                  />
                )}
                <ChevronRight className={classes.chevron} />
              </Box>
            </Box>
          );
        }
      )}
      <Button
        component={RouterLink}
        to="/games"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        View All Recent
      </Button>
    </Grid>
  );
};

export default LatestGames;
