import React from 'react';

import Template from 'structure/Template';
import More from 'panels/More';
import Articles from 'hub/Articles';

import { useQuery } from '@tanstack/react-query';
import { fetchArticles } from 'resources/api';

const Hub = () => {
  const { data:payload } = useQuery(['articles'],fetchArticles);

  if (!payload) return null;
  const {
    id,
    article_title,
    article_image,
    article_video_url,
    release_date,
    tags,
    author_id,
    author_name,
    author_job_title,
    author_external,
    teaser_copy,
  } = payload[0];

  const author = author_id
    ? `${author_name}, ${author_job_title}`
    : author_external;

  return (
    <Template type="hub">
      <More
        details={{
          image: article_image,
          video: article_video_url,
          title: article_title,
          subtitle: `${release_date} by ${author}`,
          content: teaser_copy,
          footnote: tags,
          link: `hub-article/${id}/${article_title}`,
        }}
        socialmedia
      />
      <Articles allArticles={payload} />
    </Template>
  );
};

export default Hub;
