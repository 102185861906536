import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Link, LinkProps } from 'react-router-dom';

import pages from 'resources/pages';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  closeMenuButton: {
    marginLeft: 'auto',
    marginRight: 0,
  },
}));

interface IProps {
  isOpen: boolean;
  handleClick: () => void;
}

const Menu = ({ isOpen, handleClick }: IProps) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={handleClick}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <IconButton onClick={handleClick} className={classes.closeMenuButton}>
        <CloseIcon />
      </IconButton>
      <List>
        {pages
          .filter((page) => !page.menuHide)
          .map((page) => (
            <ListItem
              button
              key={page.url}
              component={forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
                (props, ref) => (
                  <Link to={`/${page.url}`} {...props} ref={ref as any} />
                )
              )}
            >
              <ListItemText primary={page.title} />
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
};

export default Menu;
