import React from 'react';

import Template from 'structure/Template';
import More1 from 'panels/More';
import More2 from 'parents/More';

const Parents = () => {
  return (
    <Template type="parents">
      <More1 block="parents-panel-1" details={{ link: 'parents-controls' }} />
      <More2 />
      <More1 block="parents-panel-4" details={{ link: 'https://www.taminggaming.com/accessibility' }} reverse />
    </Template>
  );
};

export default Parents;
