import React from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { PanelType } from 'resources/constants';

import Content from 'panels/Content';

import mobile from 'assets/ratings_process_mobile.png';
import full from 'assets/ratings_process_full.png';

const useStyles = makeStyles(() => ({
  content: {
    marginTop: '2.6rem',
  },
}));

const Process = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Content type={PanelType.plainRed}>
      <Typography variant="h4" align="center">
        The examination process
      </Typography>
      <Box className={classes.content}>
        <img
          src={matches ? full : mobile}
          alt="Examination process"
          width="100%"
        />
      </Box>
    </Content>
  );
};

export default Process;
