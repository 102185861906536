import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Interweave } from 'interweave';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Grid } from '@material-ui/core';

import { globalStyle } from 'resources/constants';
import { useVSCContext } from 'resources/hooks';
import { getBlock } from 'resources/utils';
import Video from 'panels/Video';

const useStyles = makeStyles(({ palette }) =>
  Object.assign({}, globalStyle, {
    gridItem: {
      padding: '4rem',
    },
    video: {
      marginBottom: '3rem',
    },
    content: {
      padding: '2.6rem 3.65rem',
    },
  })
);

const More = () => {
  const classes = useStyles();
  const { contentContext } = useVSCContext();
  const panel1 = getBlock(contentContext, `ratings-panel-1`);
  const panel2 = getBlock(contentContext, `ratings-panel-2`);

  return (
    <Grid container>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        {panel1.video && (
          <Box className={classes.video}>
            <Video url={panel1.video} />
          </Box>
        )}
        <Typography variant="h2" align="center">
          {panel1.title}
        </Typography>
        <Typography variant="body1">
          <Interweave content={panel1.content} />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/ratings-system"
          className={classes.buttonCentred}
          style={{ marginTop: '2rem' }}
        >
          Discover More
        </Button>
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        {panel2.video && (
          <Box className={classes.video}>
            <Video url={panel2.video} />
          </Box>
        )}
        <Typography variant="h2" align="center">
          {panel2.title}
        </Typography>
        <Typography variant="body1">
          <Interweave content={panel2.content} />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/ratings-process"
          className={classes.buttonCentred}
          style={{ marginTop: '2rem' }}
        >
          View Our Processes
        </Button>
      </Grid>
    </Grid>
  );
};

export default More;
