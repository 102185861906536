import React from 'react';
import { Typography } from '@material-ui/core';

import { PanelType } from 'resources/constants';

import Template from 'structure/Template';
import Download from 'panels/Download';
import More from 'panels/More';
import Content from 'panels/Content';

const RatingsDownloads = () => {
  return (
    <Template type="ratings-downloads">
      <More block="ratings-downloads-panel-1" />
      <Content type={PanelType.plainRed}>
        <Typography variant="h4" align="center">
          Select your document
        </Typography>
        <Download type="ratings" />
      </Content>
    </Template>
  );
};

export default RatingsDownloads;
