import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ReactPlayer from "react-player";
import { Interweave } from "interweave";
import { makeStyles, Typography, Box, Link } from "@material-ui/core";
import {
  LocalOffer as TagIcon,
  Person as PersonIcon,
  AccessTime as ClockIcon,
} from "@material-ui/icons";

import { PanelType } from "resources/constants";
import { IArticle } from "resources/interface";

import Content from "panels/Content";
import SocialMedia from "./SocialMedia";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  header: {
    textAlign: "center",
  },
  details: {
    display: "flex",
    color: palette.primary.main,
    width: "fit-content",
    margin: "0 auto",
  },
  image: {
    height: "auto",
    width: "100%",
    margin: "20px auto",
    [breakpoints.up("md")]: {
      width: "52rem",
      margin: "4.17rem auto",
    },
  },
  tag: {
    color: palette.primary.main,
    transform: "scale(-1,1)",
    width: "1.5rem",
    verticalAlign: "middle",
    margin: "0 0.5rem",
  },
  main: {
    marginTop: "2rem",
    [breakpoints.up("md")]: {
      columnCount: 2,
      columnGap: "5rem",
    },
  },
  social: {
    borderTop: `2px solid ${palette.text.primary}`,
    borderBottom: `2px solid ${palette.text.primary}`,
    marginTop: "2rem",
  },
}));

interface IProps {
  article: IArticle;
}
const Article = ({ article }: IProps) => {
  const classes = useStyles();
  const author = article.author_id
    ? `${article.author_name}, ${article.author_job_title}`
    : article.author_external;

  return (
    <Content type={PanelType.smallTicks} hideGradient>
      <Link component={RouterLink} to="/hub">
        {`<< Back to News`}
      </Link>
      <Box className={classes.header}>
        <Typography variant="h1">{article.article_title}</Typography>
        <Typography variant="body2" className={classes.details}>
          <PersonIcon className={classes.tag} />
          {author}
          <ClockIcon className={classes.tag} />
          {article.release_date}
          <i>
            <TagIcon className={classes.tag} />
            {article.tags}
          </i>
        </Typography>
        <Box className={classes.social}>
          <SocialMedia article={article} />
        </Box>
        {article.article_video_url && (
          <ReactPlayer
            url={article.article_video_url}
            className={classes.image}
            controls
          />
        )}
        {article.article_image && !article.article_video_url && (
          <img
            src={article.article_image}
            alt={article.article_title}
            className={classes.image}
          />
        )}
        <Typography variant="subtitle1" gutterBottom>
          {article.teaser_copy}
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.main}>
        <Interweave content={article.main_copy} />
      </Typography>
    </Content>
  );
};

export default Article;
