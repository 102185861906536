import React, { ReactNode, createContext } from 'react';

import { IContent, IUser } from 'resources/interface';
/*
const requestHostInterceptor: (apiHost: string) => RequestInterceptor = (
  apiHost
) => () => async (action) => {
  return {
    ...action,
    endpoint: `${apiHost}${action.endpoint}`,
    credentials: 'same-origin',
  };
};

const responseInterceptor: ResponseInterceptor = (client) => async (
  action,
  response
) => {
  if (response?.payload[0] && response?.payload[0].release_date) {
    return {
      ...response,
      payload: response.payload.map((obj: any) => {
        const dateParts = obj.release_date.split('/');
        const dateObject = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );
        const dateFormatted = new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        }).format(dateObject);
        return {
          ...obj,
          release_date: dateFormatted,
        };
      }),
    };
  }
  return response;
};

const cache = createCache<QueryResponse<any>>(
  (action) => {
    return action.method === 'GET';
  },
  () => true
);

const client = createClient({
  requestInterceptors: [
    requestHostInterceptor(
      `${process.env.REACT_APP_HOST}${process.env.REACT_APP_API_FOLDER}`
    ),
  ],
  responseInterceptors: [responseInterceptor],
  cacheProvider: cache,
});
*/

export const defaultContent: Array<IContent> = [];
export const defaultUser: IUser = {
  statusCode: '',
  setUser: (u: IUser) => {
    console.log('setUSER');
  },
};
export const VSCContext = createContext({
  contentContext: defaultContent,
  userContext: defaultUser,
  refresh: false,
});
/*
interface IProps {
  children: ReactNode;
}
export const ContextProvider = ({ children }: IProps) => {
  return (
    <ClientContextProvider client={client}>{children}</ClientContextProvider>
  );
};
*/