import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Link } from "@material-ui/core";

import { PanelType, globalStyle } from "resources/constants";
import { recentSearches } from "resources/api";

import Content from "panels/Content";
import SearchForm from "./SearchForm";
import { useQuery } from "@tanstack/react-query";

const useStyles = makeStyles(({ breakpoints }) =>
  Object.assign({}, globalStyle, {
    content: {
      position: "relative" as "relative",
      [breakpoints.up("md")]: {
        height: "100%",
      },
    },
    box: {
      [breakpoints.up("md")]: {
        position: "absolute" as "absolute",
        bottom: "3rem",
        left: "3rem",
        right: "3rem",
      },
    },
    link: {
      marginRight: "1rem",
      fontWeight: 300,
      lineHeight: "2rem",
    },
  })
);

const Search = () => {
  const classes = useStyles();
  const { data: payload } = useQuery(["searches"], recentSearches);

  return (
    <Content type={PanelType.plainRed} className={classes.content}>
      <Box className={classes.box}>
        <Typography variant="h2" color="textSecondary">
          Search PEGI
          <br />
          Age Ratings
        </Typography>
        <SearchForm />
        {payload && (
          <>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.upper}
            >
              Most popular searches
            </Typography>
            <Box marginTop={1}>
              {payload.map((game) => (
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to={`games/?q=${encodeURIComponent(game)}`}
                  className={classes.link}
                  key={game}
                >
                  {game}
                </Link>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Content>
  );
};

export default Search;
