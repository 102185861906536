import React, { useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

import { PanelType } from 'resources/constants';
import { IArticle } from 'resources/interface';

import Content from 'panels/Content';

import Thumb from './Thumb';
import Filter from './Filter';
import Search from './Search';

interface IProps {
  allArticles: IArticle[];
}
const Articles = ({ allArticles }:IProps) => {
  const [articleList, setArticleList] = useState(allArticles);

  return (
    <Content type={PanelType.smallTicksDark}>
      <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
        <Grid item xs={12} md={8}>
          <Filter setArticleList={setArticleList} allArticles={allArticles} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Search setArticleList={setArticleList} />
        </Grid>
      </Grid>
      {!articleList.length && (
        <Box style={{ textAlign: 'center', padding: '2rem' }}>
          <Typography variant="h2" color="textSecondary">
            No articles found
          </Typography>
        </Box>
      )}
      <Grid container spacing={4}>
        {articleList?.map((article) => (
          <Grid item md={4} key={article.id}>
            <Thumb article={article} />
          </Grid>
        ))}
      </Grid>
    </Content>
  );
};

export default Articles;
