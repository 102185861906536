import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { PanelType } from 'resources/constants';
import { IArticle } from 'resources/interface';

import Content from 'panels/Content';
import Thumb from './Thumb';

interface IProps {
  id: string;
  articles: IArticle[];
}
const Latest = ({ id, articles }:IProps) => {
  return (
    <Content type={PanelType.plainRed}>
      <Typography
        variant="h2"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        Recent articles
      </Typography>
      <Grid container spacing={4}>
        {articles
          .filter((article) => article.id !== id)
          .map((article, index) => (
            <Fragment key={article.id}>
              {index < 3 && (
                <Grid item md={4}>
                  <Thumb article={article} />
                </Grid>
              )}
            </Fragment>
          ))}
      </Grid>
    </Content>
  );
};

export default Latest;
