import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Interweave } from 'interweave';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { fetchFAQ } from 'resources/api';
import { globalStyle, PanelType } from 'resources/constants';
import Content from 'panels/Content';

const useStyles = makeStyles(({ breakpoints }) =>
  Object.assign({}, globalStyle, {
    faq: {
      backgroundColor: 'transparent',
      [breakpoints.up('md')]: {
        margin: '0 4rem',
      },
    },
    expand: {
      width: '4rem',
      height: '4rem',
    },
  })
);

const FAQ = () => {
  const classes = useStyles();
  const { data:payload } = useQuery(['faq'],fetchFAQ);

  if (!payload) return null;

  return (
    <Content type={PanelType.smallTicks} hideGradient>
      <Typography variant="h2" align="center">
        Frequently asked questions
      </Typography>
      {Array.isArray(payload) &&
        payload.map((faq) => (
          <ExpansionPanel className={classes.faq}>
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon color="primary" className={classes.expand} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{faq.question}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="body1">
                <Interweave content={faq.answer} />
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
    </Content>
  );
};

export default FAQ;
