import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useVSCContext } from 'resources/hooks';
import { getBlock } from 'resources/utils';
import { PanelType } from 'resources/constants';

import Content from 'panels/Content';
import Download from 'panels/Download';

const useStyles = makeStyles(({ palette }) => ({
  topBorder: {
    borderTop: `1px solid ${palette.common.white}`,
    marginTop: '2rem',
    paddingTop: '2rem',
  },
}));

const SchoolsDownload = () => {
  const classes = useStyles();
  const { contentContext } = useVSCContext();
  const panel1 = getBlock(contentContext, 'schools-panel-1');
  const panel2 = getBlock(contentContext, 'schools-panel-2');

  return (
    <>
      <Content type={PanelType.plain}>
        <Typography variant="h2" align="center">
          {panel1.title}
        </Typography>
        <Typography variant="body1" align="center">
          {panel1.content}
        </Typography>
        <Download type="schools-posters" />
      </Content>

      <Content type={PanelType.smallTicksDark}>
        <Typography variant="h2" align="center" color="textSecondary">
          {panel2.title}
        </Typography>
        <Typography variant="body1" align="center">
          {panel2.content}
        </Typography>
        <Typography variant="h4" align="center" className={classes.topBorder}>
          Primary school resources
        </Typography>
        <Download type="schools-primary" />
        <Typography variant="h4" align="center" className={classes.topBorder}>
          Secondary school resources
        </Typography>
        <Download type="schools-secondary" />
      </Content>
    </>
  );
};

export default SchoolsDownload;
