import React from 'react';
import { Interweave } from 'interweave';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fetchTeam } from 'resources/api';
import { PanelType, globalStyle } from 'resources/constants';

import Content from 'panels/Content';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles(({ palette }) =>
  Object.assign({}, globalStyle, {
    image0: {
      width: '100%',
      border: `0.5rem solid ${palette.common.white}`,
    },
    image1: {
      width: '100%',
      border: `0.5rem solid ${palette.text.primary}`,
    },
  })
);

const Team = () => {
  const classes = useStyles();
  const { data:payload } = useQuery(["team"],fetchTeam);

  if (!payload) return null;

  return (
    <>
      {payload.map((member, index) => {
        if (index % 2)
          return (
            <Content type={PanelType.plain}>
              <Grid container spacing={4}>
                <Grid item md={8}>
                  <Typography variant="h4" color="primary" gutterBottom>
                    {member.name}
                  </Typography>
                  <Typography variant="h6" color="textPrimary" gutterBottom>
                    {member.job_title}
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    <Interweave content={member.about} />
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <img
                    src={member.image}
                    alt={member.name}
                    className={classes.image1}
                  />
                </Grid>
              </Grid>
            </Content>
          );

        return (
          <Content type={PanelType.smallTicksDark}>
            <Grid container spacing={4}>
              <Grid item md={4}>
                <img
                  src={member.image}
                  alt={member.name}
                  className={classes.image0}
                />
              </Grid>
              <Grid item md={8}>
                <Typography variant="h4" gutterBottom>
                  {member.name}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {member.job_title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <Interweave content={member.about} />
                </Typography>
              </Grid>
            </Grid>
          </Content>
        );
      })}
    </>
  );
};

export default Team;
