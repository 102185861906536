import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { globalStyle } from 'resources/constants';
import { fetchPlatforms } from 'resources/api';
import { IPlatform, IRestriction } from 'resources/interface';

const useStyles = makeStyles(() =>
  Object.assign({}, globalStyle, {
    grid: {
      maxWidth: '80rem',
      margin: 'auto',
    },
    gridItem: {
      position: 'relative' as 'relative',
      '& img': {
        width: '100%',
      },
      '&:hover': {
        '& div': {
          display: 'none',
        },
      },
    },
  })
);

interface IProps {
  restriction: IRestriction;
  platform?: Partial<IPlatform>;
}
const ControlsPlatform = ({
  restriction,
  platform,
}:IProps) => {
  const classes = useStyles();
  const { data:payload } = useQuery(['platforms'],fetchPlatforms);
  const platformId = platform ? platform.id : '';

  return (
    <Grid container spacing={2} className={classes.grid}>
      {payload?.map(({ id, platform_name, platform_icon }) => (
        <Grid item xs={4} md={2} className={classes.gridItem} key={id}>
          <Link
            component={RouterLink}
            to={`/parents-controls-article/${restriction.id}/${id}/${restriction.restriction_name}/${platform_name}`}
          >
            <img src={platform_icon} alt={platform_name} />
            {platformId !== id && <div className={classes.overlay} />}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default ControlsPlatform;
