import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Interweave } from "interweave";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
import { LocalOffer as TagIcon } from "@material-ui/icons";

import { globalStyle } from "resources/constants";
import { useVSCContext } from "resources/hooks";
import { getBlock } from "resources/utils";

import SocialMedia from "hub/SocialMedia";
import Video from "./Video";

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    container: {
      padding: "4rem",
    },
    item: {
      alignSelf: "center",
    },
    title: {
      marginTop: "-32px",
      [breakpoints.up("md")]: {
        marginTop: "initial",
      },
    },
    tag: {
      color: palette.primary.main,
      transform: "scale(-1,1)",
      width: "1.5rem",
      verticalAlign: "middle",
      marginRight: "0.5rem",
    },
  })
);

const mapPos = {
  lat: 51.908687,
  lng: -0.218562,
};

interface IDetails {
  title?: string;
  subtitle?: string;
  image?: string;
  video?: string;
  content?: string;
  footnote?: string;
  link?: string;
  linkLabel?: string;
}

interface IProps {
  block?: string;
  details?: IDetails;
  useMap?: boolean;
  socialmedia?: boolean;
  reverse?: boolean;
}
const More = ({
  block,
  details,
  useMap = false,
  socialmedia = false,
  reverse = false,
}: IProps) => {
  const classes = useStyles();
  const { contentContext } = useVSCContext();

  let { title, subtitle, image, video, content, footnote, link, linkLabel } =
    details || {};

  if (block) {
    const panel = getBlock(contentContext, block);
    title = panel.title;
    content = panel.content;
    image = panel.image;
    video = panel.video;
  }

  const justText =
    (image && !image.includes("workle-no-image")) || video || useMap;

  return (
    <Grid container className={classes.container} spacing={8}>
      {image && !image.includes("workle-no-image") && !reverse && (
        <Grid item className={classes.item} xs={12} md={6}>
          <img src={image} alt={title} width="100%" />
        </Grid>
      )}
      {video && (
        <Grid item className={classes.item} xs={12} md={6}>
          <Video url={video} />
        </Grid>
      )}
      {useMap && (
        <Grid item className={classes.item} xs={12} md={6}>
          <LoadScript googleMapsApiKey="AIzaSyAlGHE_9MvgPvtog0sSxlfqXJZbwqbv9m4">
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "30vh" }}
              center={mapPos}
              zoom={12}
            >
              <Marker position={mapPos} label="Games Rating Authority" />
            </GoogleMap>
          </LoadScript>
        </Grid>
      )}
      <Grid item className={classes.item} xs={12} md={justText ? 6 : 12}>
        {link && socialmedia && <SocialMedia link={link} reduced />}
        {title && (
          <Typography variant="h2" color="primary" className={classes.title}>
            {title}
          </Typography>
        )}
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        <Typography variant="body1" gutterBottom>
          <Interweave content={content} disableLineBreaks />
        </Typography>
        {footnote && (
          <Typography variant="body1">
            <i>
              <TagIcon className={classes.tag} />
              {footnote}
            </i>
          </Typography>
        )}
        {link &&
          (link.startsWith("http") ? (
            <Button
              variant="contained"
              color="primary"
              href={link}
              target="_blank"
              className={classes.buttonRight}
            >
              {linkLabel || "Tell me more"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={`/${link}`}
              className={classes.buttonRight}
            >
              {linkLabel || "Tell me more"}
            </Button>
          ))}
      </Grid>
      {image && !image.includes("workle-no-image") && reverse && (
        <Grid item className={classes.item} xs={12} md={6}>
          <img src={image} alt={title} width="100%" />
        </Grid>
      )}
    </Grid>
  );
};

export default More;
