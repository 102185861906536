import {
  IArticle,
  IContent,
  IControl,
  ICollaboration,
  ICourse,
  IDownload,
  IFAQ,
  IFetchControls,
  IGame,
  IHero,
  ILogin,
  IPEGIGenre,
  IPEGIPlatform,
  IPlatform,
  IRating,
  IRestriction,
  ISearchGames,
  ISchoolPasswords,
  ISchoolRegistration,
  ITeam,
} from './interface';

const host = `${process.env.REACT_APP_HOST}${process.env.REACT_APP_API_FOLDER}`

export const fetchAuth = async () => {
  const data = await fetch(`${host}/auth.html`)
  return await data.json() as Array<any>;
}

export const fetchContent = async () => {
  const data = await fetch(`${host}/dynamic-blocks.html`)
  return await data.json() as IContent[];
}

export const fetchHeros = async () => {
  const data = await fetch(`${host}/home-hero.html`)
  return await data.json() as IHero[];
}

export const fetchArticles = async () => {
  const data = await fetch(`${host}/hub-articles.html`)
  return await data.json() as IArticle[];
}

export const searchArticles = async (searchString: string) => {
  const data = await fetch(`${host}/request.ashx?action=search-filter-articles&searchText=${searchString}`)
  return await data.json() as IArticle[];
}

export const filterArticles = async (filter: string) => {
  const data = await fetch(`${host}/request.ashx?action=search-filter-articles&tags=${filter}`)
  return await data.json() as IArticle[];
}

export const allTags = async () => {
  const data = await fetch(`${host}/request.ashx?action=search-tags-all`)
  return await data.json() as {tags: string[]};
}

export const fetchFAQ = async () => {
  const data = await fetch(`${host}/faq.html`)
  return await data.json() as IFAQ[];
}

export const fetchRatings = async () => {
  const data = await fetch(`${host}/pegi-ratings.html`)
  return await data.json() as IRating[];
}

export const fetchDownloads = async (sectionName: string) => {
  const data = await fetch(`${host}/downloads.html?sectionName=${sectionName}`)
  return await data.json() as IDownload[];
}

export const fetchRestrictions = async () => {
  const data = await fetch(`${host}/controls-restrictions.html`)
  return await data.json() as IRestriction[];
}

export const fetchPlatforms = async () => {
  const data = await fetch(`${host}/controls-platforms.html`)
  return await data.json() as IPlatform[];
}

export const fetchControls = async ({
  platformNameId,
  restrictionTypeId,
}:IFetchControls) => {
  const data = await fetch(`${host}/parental-controls.html?platformNameId=${platformNameId}&restrictionTypeId=${restrictionTypeId}`)
  return await data.json() as IControl[];
}

export const fetchTeam = async () => {
  const data = await fetch(`${host}/team.html`)
  return await data.json() as ITeam[];
}

export const fetchCollaborations = async () => {
  const data = await fetch(`${host}/collaborations.html`)
  return await data.json() as ICollaboration[];
}

export const fetchSchoolCourses = async () => {
  const data = await fetch(`${host}/school-courses.html`)
  return await data.json() as ICourse[];
}

export const fetchRetailCourses = async () => {
  const data = await fetch(`${host}/retail-courses.html`)
  return await data.json() as ICourse[];
}

export const fetchPEGIGenres = async () => {
  const data = await fetch(`${host}/game-genres.html`)
  return await data.json() as IPEGIGenre[];
}

export const fetchPEGIPlatforms = async () => {
  const data = await fetch(`${host}/game-platforms.html`)
  return await data.json() as IPEGIPlatform[];
}

export const searchGames = async ({
  id,
  gameTitle,
  ageCategoryCsv,
  genreId,
  platformId,
  publisherId,
  releaseDate,
  sortBy,
  pageSize,
  pageNumber,
}:ISearchGames) => {
  const data = await fetch(`${host}/request.ashx?action=game-search${
    gameTitle ? `&gameTitle=${gameTitle}` : ''
  }${id ? `&id=${id}` : ''}${
    ageCategoryCsv ? `&ageCategoryCsv=${ageCategoryCsv}` : ''
  }${genreId ? `&genreId=${genreId}` : ''}${
    platformId ? `&platformId=${platformId}` : ''
  }${publisherId ? `&publisherId=${publisherId}` : ''}${
    releaseDate ? `&releaseDate=${releaseDate}` : ''
  }${sortBy ? `&sortBy=${sortBy}` : ''}${
    pageSize ? `&pageSize=${pageSize}` : ''
  }${pageNumber ? `&pageNumber=${pageNumber}` : ''}`)
  return await data.json() as IGame[];
}

export const recentSearches = async () => {
  const data = await fetch(`${host}/request.ashx?action=game-recent-searches&maxResults=8`)
  return await data.json() as string[];
}

export const postLogin = async (formValues: ILogin) => {
  const data = await fetch(`${host}/request.ashx?action=auth-login`, {
    method: 'POST',
    body: JSON.stringify(formValues),
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  })
  return await data.json();
}

export const postSchoolRegistration = async (formValues: ISchoolRegistration) => {
  const data = await fetch(`${host}/request.ashx?action=application-school`, {
    method: 'POST',
    body: JSON.stringify(formValues),
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  })
  return await data.json();
}

export const postSchoolPasswords = async (formValues: ISchoolPasswords) => {
  const data = await fetch(`${host}/request.ashx?action=pwd-update-school`, {
    method: 'POST',
    body: JSON.stringify(formValues),
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  })
  return await data.json();
}

/*
type TPostNewsletter = (formValues: INewsletter) => Action;
export const postNewsletter: TPostNewsletter = (formValues) => ({
  method: 'POST',
  endpoint: '/subscribe/post?u=650f8c8a357d864c65edecbef&id=1f31bcf7a4',
  body: formValues,
});
*/
