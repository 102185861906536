import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Interweave } from 'interweave';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Grid } from '@material-ui/core';

import { globalStyle } from 'resources/constants';
import { useVSCContext } from 'resources/hooks';
import { getBlock } from 'resources/utils';

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    content: {
      color: palette.common.white,
      padding: '4rem',
      [breakpoints.up('md')]: {
        backgroundSize: 'cover',
      },
      '& h2': {
        color: palette.common.white,
        [breakpoints.up('md')]: {
          marginTop: '21rem',
        },
      },
    },
  })
);

interface IProps {
  block: string;
  url: string;
}
const MoreImage = ({ block, url }:IProps) => {
  const classes = useStyles();
  const { contentContext } = useVSCContext();
  const panel = getBlock(contentContext, block);

  return (
    <Box
      className={classes.content}
      style={{ backgroundImage: `url(${panel.image})` }}
    >
      <Typography variant="h2">{panel.title}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={10}>
          <Typography variant="subtitle1">
            <Interweave content={panel.content} />
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            component={RouterLink}
            to={`/${url}`}
            color="secondary"
            className={classes.buttonRight}
          >
            Find Out More
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MoreImage;
