import React, { useState } from 'react';

import { Typography, Box, Button, makeStyles } from '@material-ui/core';

import { PanelType } from 'resources/constants';

import Content from 'panels/Content';

import RegistrationForm from './RegistrationForm';
import LoginForm from './LoginForm';

const useStyles = makeStyles(({ breakpoints }) => ({
  login: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    [breakpoints.up('md')]: {
      marginLeft: '2rem',
      padding: '0 3rem',
    },
  },
}));

interface IProps {
  type: 'schools' | 'retail';
}
const Login = ({ type }: IProps) => {
  const classes = useStyles();
  const [registration, setRegistration] = useState(false);

  return (
    <Content
      type={type === 'retail' ? PanelType.smallTicksDark : PanelType.plainRed}
    >
      <Typography variant="h2" color="textSecondary" align="center">
        {`Access our ${
          type === 'retail' ? 'training courses' : 'e-learning facility'
        }`}
      </Typography>
      <Box className={classes.login}>
        {registration ? <RegistrationForm /> : <LoginForm />}
        {!registration && type === 'schools' && (
          <Button
            type="submit"
            variant="contained"
            className={classes.button}
            color="secondary"
            onClick={() => setRegistration(true)}
          >
            Register
          </Button>
        )}
      </Box>
    </Content>
  );
};

export default Login;
