import React, { FC, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Interweave } from 'interweave';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import pages from 'resources/pages';
import { fetchHeros } from 'resources/api';
import { useVSCContext } from 'resources/hooks';
import { getBlock } from 'resources/utils';
import { globalStyle } from 'resources/constants';

const useStyles = makeStyles(({ palette, breakpoints }) =>
  Object.assign({}, globalStyle, {
    image: {
      width: '100%',
      height: '100%',
    },
    carouselContent: {
      position: 'absolute' as 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      padding: '10px 20px',
      [breakpoints.up('md')]: {
        padding: '2rem 4rem',
      },
      opacity: 0.8,
      color: palette.common.white,
      backgroundColor: palette.secondary.main,
      textAlign: 'left' as 'left',
      '& h1': {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginBottom: '25px',
        [breakpoints.up('md')]: {
          marginBottom: '1rem',
        },
      },
      '& h6': {
        display: 'none',
        [breakpoints.up('md')]: {
          display: 'initial',
        },
      },
    },
    header: {
      marginBottom: '1rem',
    },
    heroBox: {
      backgroundSize: 'cover' as 'cover',
      position: 'relative' as 'relative',
      height: '300px',
      [breakpoints.up('md')]: {
        height: '34rem',
      },
    },
    content: {
      color: palette.common.white,
      backgroundColor: palette.secondary.main,
      opacity: 0.8,
      padding: '20px',
      [breakpoints.up('md')]: {
        padding: '3rem 4rem',
      },
      position: 'absolute' as 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
  })
);

interface IProps {
  type: string;
}
const Hero: FC<IProps> = ({ type }) => {
  const classes = useStyles();
  const { data:payload } = useQuery(['heroes'],fetchHeros);
  const { contentContext } = useVSCContext();
  const [redirect, setRedirect] = useState('');

  if (redirect.length) {
    return <Navigate to={redirect} />;
  }

  if (type === 'home')
    return (
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={true}
        autoPlay={true}
        infiniteLoop={true}
      >
        {payload?.map((hero) => (
          <Box
            className={classes.clickable}
            onClick={() => {
              if (hero.click_link_url) setRedirect(hero.click_link_url);
            }}
            key={hero.id}
          >
            <img
              src={hero.hero_image}
              alt={hero.panel_name}
              className={classes.image}
            />
            <Box className={classes.carouselContent}>
              <Typography variant="h1">{hero.panel_name}</Typography>
              <Typography variant="subtitle1">{hero.subtitle}</Typography>
            </Box>
          </Box>
        ))}
      </Carousel>
    );

  const currPage = pages.find((page) => page.url === type);
  if (!currPage || !currPage.hero) return null;

  const hero = getBlock(contentContext, `${type}-hero`);

  return (
    <Box
      className={classes.heroBox}
      style={{
        backgroundImage: `url(${currPage.hero})`,
      }}
    >
      <Box className={classes.content}>
        <Typography variant="h1" className={classes.header}>
          {hero.title}
        </Typography>
        <Typography variant="subtitle1">
          <Interweave content={hero.content} />
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
