import React, { useState, useEffect } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';

import { filterArticles, allTags } from 'resources/api';
import { IArticle } from 'resources/interface';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    width: 'auto',
    border: '1px solid white',
    margin: '0 1rem 1rem 0',
    color: palette.common.white,
    lineHeight: '30px',
    [breakpoints.up('md')]: {
      lineHeight: '3rem',
    },
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: palette.common.white,
      color: palette.common.black,
    },
  },
  highlight: {
    backgroundColor: palette.common.white,
    color: palette.common.black,
  },
}));

interface IProps {
  setArticleList: (articles: any) => void;
  allArticles: IArticle[];
}
const Filter = ({ setArticleList, allArticles }: IProps) => {
  const classes = useStyles();
  const [tag, setTag] = useState('all');
  const { isLoading, error, data: payload, refetch } = useQuery(["articles", tag], () => filterArticles(tag), {enabled: false});
  const { data} = useQuery(["tags"], allTags);

  useEffect(() => {
    if (tag !== 'all' && tag !== '') {refetch()} else setArticleList(allArticles)
  }, [tag]);

  useEffect(() => {
    if (payload?.length || allArticles.length < allArticles.length) setArticleList(payload)
  }, [payload]);

  return (
    <Box className={classes.tags}>
      <Button
        className={`${classes.tag} ${
          tag === '' || tag === 'all' ? classes.highlight : null
        }`}
        onClick={() => setTag('all')}
      >
        View all
      </Button>
      {data?.tags?.map((tagName) => (
          <Button
            className={`${classes.tag} ${
              tag === tagName ? classes.highlight : null
            }`}
            onClick={() => setTag(tagName)}
            key={tagName}
          >
            {tagName}
          </Button>
        ))}
    </Box>
  );
};

export default Filter;
