import React from 'react';

import Template from 'structure/Template';
import More from 'panels/More';
import History from 'about/History';

const AboutHistory = () => {
  return (
    <Template type="about-history">
      <More block="about-history-panel-1" />
      <History />
    </Template>
  );
};

export default AboutHistory;
