import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useVSCContext } from 'resources/hooks';
import { getBlock } from 'resources/utils';
import { PanelType, globalStyle } from 'resources/constants';

import Content from 'panels/Content';
import { Interweave } from 'interweave';

const useStyles = makeStyles(() =>
  Object.assign({}, globalStyle, {
    image: {
      width: '100%',
      marginBottom: '2rem',
    },
    content: {
      minHeight: '6rem',
    },
  })
);

const More = () => {
  const classes = useStyles();
  const { contentContext } = useVSCContext();
  const panel1 = getBlock(contentContext, 'about-panel-4');
  const panel2 = getBlock(contentContext, 'about-panel-5');

  return (
    <Content type={PanelType.largeTickDark}>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <img
            src={panel1.image}
            alt={panel1.title}
            width="100%"
            className={classes.image}
          />
          <Typography variant="h4" align="center" gutterBottom>
            {panel1.title}
          </Typography>
          <Typography variant="body1" className={classes.content}>
            <Interweave content={panel1.content} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/about-media"
            className={classes.buttonCentred}
          >
            Company Downloads
          </Button>
        </Grid>
        <Grid item md={6}>
          <img
            src={panel2.image}
            alt={panel2.title}
            className={classes.image}
          />
          <Typography variant="h4" align="center" gutterBottom>
            {panel2.title}
          </Typography>
          <Typography variant="body1" className={classes.content}>
            <Interweave content={panel2.content} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/about-collaborations"
            className={classes.buttonCentred}
          >
            Find Out More
          </Button>
        </Grid>
      </Grid>
    </Content>
  );
};

export default More;
