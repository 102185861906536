import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { makeStyles, Box } from "@material-ui/core";

import { ReactComponent as FacebookIcon } from "assets/sm-facebook.svg";
import { ReactComponent as LinkedinIcon } from "assets/sm-linkedin.svg";
import { ReactComponent as TwitterIcon } from "assets/sm-twitter.svg";
import { ReactComponent as WhatsappIcon } from "assets/sm-whatsapp.svg";
import { ReactComponent as EmailIcon } from "assets/sm-email.svg";

const useStyles = makeStyles(({ breakpoints }) => ({
  social: {
    display: "flex",
    width: "fit-content",
    marginBottom: "2rem",
  },
  button: {
    marginLeft: "1rem",
    "& svg": {
      verticalAlign: "middle",
    },
  },
  icon: {
    width: "30px",
    height: "30px",
    [breakpoints.up("md")]: {
      width: "4rem",
      height: "4rem",
    },
  },
}));

interface IProps {
  id: string;
  title: string;
}
const SocialMedia = ({ id, title }: IProps) => {
  const classes = useStyles();
  const shareUrl = `${process.env.REACT_APP_HOST}${
    process.env.REACT_APP_BASENAME
  }game/${id}/${encodeURIComponent(title.replace(" ", "_"))}`;

  return (
    <Box className={classes.social}>
      <FacebookShareButton
        url={shareUrl}
        quote={title}
        className={classes.button}
      >
        <FacebookIcon className={classes.icon} />
      </FacebookShareButton>
      <TwitterShareButton
        url={shareUrl}
        title={title}
        className={classes.button}
      >
        <TwitterIcon className={classes.icon} />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} className={classes.button}>
        <LinkedinIcon className={classes.icon} />
      </LinkedinShareButton>
      <WhatsappShareButton
        url={shareUrl}
        title={title}
        separator=":: "
        className={classes.button}
      >
        <WhatsappIcon className={classes.icon} />
      </WhatsappShareButton>
      <EmailShareButton
        url={shareUrl}
        subject={title}
        body="Have a look at this game on the Games Rating Authority website: "
        className={classes.button}
        style={{ marginRight: "0" }}
      >
        <EmailIcon className={classes.icon} />
      </EmailShareButton>
    </Box>
  );
};

export default SocialMedia;
