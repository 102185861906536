import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { PanelType } from "resources/constants";

import Content from "panels/Content";

import mobile from "assets/about_history_mobile.png";
import full from "assets/about_history_full.png";

const useStyles = makeStyles(() => ({
  content: {
    marginTop: "2.6rem",
  },
}));

const Process = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Content type={PanelType.smallTicksRed}>
      <Box className={classes.content}>
        <img
          src={matches ? full : mobile}
          alt="The history of the GRA"
          width="100%"
        />
      </Box>
    </Content>
  );
};

export default Process;
