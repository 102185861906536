import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, TextField, Button, makeStyles } from '@material-ui/core';

import { searchArticles } from 'resources/api';

import { Search as SearchIcon } from '@material-ui/icons';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles(({ palette }) => ({
  button: {
    width: 'auto',
    border: '1px solid white',
    padding: '0 !important',
    marginLeft: '2rem',
    '& svg': {
      fill: palette.common.white,
      fontSize: '3em',
    },
  },
}));

const validationSchema = Yup.object({
  search: Yup.string().required('Enter all or part of a game title'),
});

const Form = (props: any) => {
  const classes = useStyles();

  const {
    values: { search },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex">
        <TextField
          name="search"
          helperText={touched.search ? errors.search : ''}
          label="Search all articles"
          value={search}
          onChange={handleChange}
          required
          variant="filled"
        />
        <Button type="submit" disabled={!isValid} className={classes.button}>
          <SearchIcon />
        </Button>
      </Box>
    </form>
  );
};

interface IProps {
  setArticleList: (articles: any) => void;
}
const SearchForm = ({ setArticleList }: IProps) => {
  const [search, setSearch] = useState('');
  const { isLoading, error, data: payload, refetch } = useQuery(["articles", search], () => searchArticles(search), {enabled: false});

  useEffect(() => {
    if (search.length) refetch();
  }, [search]);

  useEffect(() => {
    if (payload?.length) setArticleList(payload)
  }, [payload]);

  const values = { search: '' };

  const submit = async ({ search }: { search: string }) => {
    setSearch(search);
  };

  return (
    <Formik
      render={(props) => <Form {...props} />}
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={submit}
    />
  );
};

export default SearchForm;
