import React from 'react';

import Template from 'structure/Template';
import Controls from 'parents/Controls';

const ParentsControls = () => {
  return (
    <Template type="parents-controls">
      <Controls />
    </Template>
  );
};

export default ParentsControls;
